import React, { ReactNode } from "react";

interface IProps {
  children?: ReactNode;
}

const Empty: React.FC<IProps> = (props: IProps) => {
  const { children } = props;

  return <div className="empty">{children}</div>;
};

export default Empty;
