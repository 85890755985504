import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";

const MAX_LENGTH = 20;

type Props = {
  item: { label: string; url: string; logo: string };
};

const Item: React.FC<Props> = (props: Props) => {
  const {
    item: { label, url, logo },
  } = props;

  const getShortName = useCallback(
    (name: string) => name.substring(0, MAX_LENGTH) + "...",
    []
  );

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${logo})`,
        }}
        className="image"
        title={label}
      ></div>
      <div className="carousel-item-infos">
        <div className="carousel-item-title full-w">
          {label.length > MAX_LENGTH ? getShortName(label) : label}
        </div>
        {url && (
          <div>
            <a
              className="carousel-item-url"
              target="_blank"
              href={url}
              rel="noopener noreferrer"
            >
              Vers leur site
            </a>
            &nbsp;
            <FontAwesomeIcon icon={faArrowRight as any} />
          </div>
        )}
      </div>
    </>
  );
};

export default Item;
