import React, { useEffect, useState } from "react";

import InstanceService from "service/instance";
import useInstanceId from "hooks/useInstanceId";
import Team from "./components/Team";
import TeamMembers from "./components/TeamMembers";
import Loading from "components/Loading";
import { IInterclubTeam } from "service/service.interfaces";
import EmptyMessage from "./components/EmptyMessage";

const service = new InstanceService();
const { REACT_APP_ICBAD_URL } = process.env;

const InterclubTeams: React.FC = () => {
  const [teams, setTeams] = useState<IInterclubTeam[]>([]);
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const instanceId = useInstanceId();

  useEffect(() => {
    const { promise, cancel } = service.teams(instanceId);
    promise.then((response) => {
      setTeams(response);
      setLoading(false);
    });

    return () => cancel();
  }, [instanceId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {teams.length > 0 ?
        <div className="org-chart-container">
          <div className="org-chart-buttons-container">
            {teams.length > 1 &&
              teams.map((team, index) => (
                <Team
                  key={team.ID_Equipe}
                  team={team}
                  onClick={() => setCurrentTeamIndex(index)}
                  selected={index === currentTeamIndex}
                />
              ))}
          </div>
          <a
            style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}
            href={`${REACT_APP_ICBAD_URL}equipe/${teams[currentTeamIndex].ID_Equipe}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Voir l'équipe sur IcBad
      </a>

          {teams.length !== 0 && <TeamMembers team={teams[currentTeamIndex]} />}
        </div>
        :
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}> <EmptyMessage /></div>
      }
    </>
  );
};

export default InterclubTeams;
