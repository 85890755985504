import React, { CSSProperties } from "react";
import SafetySpacing from "layouts/SafetySpacing";

interface IProps {
  htmlContent?: string;
  style?: CSSProperties;
}

const TextEditor: React.FC<IProps> = (props: IProps) => {
  const { htmlContent = "", style } = props;

  return (
    <SafetySpacing>
      <div
        className="text-container"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{ ...style }}
      />
    </SafetySpacing>
  );
};

export default TextEditor;
