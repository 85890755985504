import { ReactNode } from "react";

import { ComponentTypes } from "./components.mapping";
import COMPONENT_FIELDS, {
  IEditorField,
  DEFAULT_PROPS,
} from "./components.fields";
import EditorUpdater from "./updater/updater.editor";
import EditorReplacer from "./replacer/replacer.editor";
import EditorAppender, { IEditableModeArgs } from "./appender/appender.editor";
import EditorRemover from "./remover/remover.editor";
import { findPropsOf, getParentIdOf } from "./utils/editor.utils";
import EditorBuilder, { IBuildOptions } from "./builder/builder.editor";

interface IPageProperties {
  id?: string;
  ownerId?: string;
  label?: string;
  description?: string;
  generateTitle?: boolean;
  components?: IPageComponentProperties[];
  menuSide?: "left" | "top"
}

export interface IPageComponentProperties {
  id: string;
  type: ComponentTypes;
  props?: any;
  parentId?: string;
}

class PageBuilder {
  public options: IBuildOptions;
  public properties: IPageProperties;
  public initialized: boolean;

  private updater: EditorUpdater;
  private replacer: EditorReplacer;
  private appender: EditorAppender;
  private remover: EditorRemover;
  public builder: EditorBuilder;

  constructor() {
    this.updater = new EditorUpdater(this);
    this.replacer = new EditorReplacer(this);
    this.appender = new EditorAppender(this);
    this.remover = new EditorRemover(this);
    this.builder = new EditorBuilder(this);
    this.properties = {};
    this.initialized = false;
  }

  public init = (properties: IPageProperties, options: IBuildOptions = {}) => {
    this.properties = properties;
    this.options = options;
    this.initialized = true;
  };

  public build = (): ReactNode[] => {
    return this.builder.build();
  };

  public cleanForPublish = (): IPageComponentProperties[] => {
    return this.builder.cleanForPublish();
  };

  public getEditableProps = (type: ComponentTypes): IEditorField[] => {
    return COMPONENT_FIELDS[type] || [];
  };

  public update = (
    componentId: string,
    newData: object
  ): {
    elements: ReactNode[];
    pageProperties: IPageProperties;
  } => {
    return this.updater.updateComponentById(componentId, newData);
  };

  public findPropsOf = (componentId: string): object | undefined => {
    return findPropsOf(componentId, this.properties.components);
  };

  public findDefaultPropsFor = (type: ComponentTypes) => {
    return DEFAULT_PROPS[type] || {};
  };

  public replace = (
    componentId: string,
    newComponent: IPageComponentProperties
  ): IPageComponentProperties | undefined => {
    return this.replacer.replaceComponentById(componentId, newComponent);
  };

  public add = (newComponents: IPageComponentProperties[]): IPageProperties => {
    this.appender.append(newComponents);
    return this.properties;
  };

  public appendAfter = (
    componentId: string,
    newComponents: IPageComponentProperties[]
  ): IPageProperties => {
    this.appender.appendAfter(componentId, newComponents);
    return this.properties;
  };

  public getParentIdOf = (componentId: string): string | undefined => {
    return getParentIdOf(componentId, this.properties.components);
  };

  public remove = (componentId: string) => {
    this.remover.remove(componentId);
  };

  public setEditableMode = (args: IEditableModeArgs) => {
    this.appender.setEditableMode(args);
  };
}

export default PageBuilder;

export { ComponentTypes, IPageProperties, IBuildOptions, IEditorField };
