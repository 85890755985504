import React, { useState, useEffect } from "react";

import Title from "components/Title";
import Skeleton from "components/Skeleton";
import QuickAccessLink from "fullComponents/QuickAccess/components/QuickAccessLink";
import { QUICKDOCUMENTACCESS_DEFAULT_PROPS } from "./editor.fields";
import QuickAccesService from "service/quickAccess/quickAccess.service";
import { useWebsiteId } from "hooks";

interface IProps {
  title?: string;
}

export interface IQuickDocumentAccessLink {
  label: string;
  url: string;
}

const service = new QuickAccesService();

const QuickDocumentAccess: React.FC<IProps> = (props: IProps) => {
  const { title = QUICKDOCUMENTACCESS_DEFAULT_PROPS.title } = props;
  const [links, setLinks] = useState<IQuickDocumentAccessLink[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const websiteId = useWebsiteId();

  useEffect(() => {
    const { promise, cancel } = service.quickDocumentAccess(websiteId);
    promise.then((response) => {
      setLinks(response);
      setIsLoading(false);
    });
    return () => cancel();
  }, [websiteId]);

  return (
    <div className="quick-access">
      <Title content={title} safetySpacing={false} />

      {isLoading && <Skeleton shape="paragraph" repeat={4} />}

      {!isLoading && (
        <div className="quick-access--items">
          {links.map((link) => (
            <QuickAccessLink key={link.label} linkTitle={link.label} linkUrl={link.url} />
          ))}
        </div>
      )}
    </div>
  );
};

export default QuickDocumentAccess;
