import { cancellablePromise } from "utils/object/object.utils";
import LocalStorageService from "./localStorage/localStorage.service";

const localStorageService = new LocalStorageService();
const DEFAULT_ERROR_MESSAGE = "Une erreur est survenue.";
enum HTTP_STATUS {
  SUCCESS = 200,
  INTERNAL_SERVER_ERROR = 500,
}

class MasterService {
  private apiURL: string;

  constructor() {
    const { REACT_APP_API_URL = "" } = process.env;
    this.apiURL = REACT_APP_API_URL;
  }

  get headers(): HeadersInit {
    const token = localStorageService.getToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  get = <T>(
    url: string,
    defaultHeaders?: HeadersInit
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;

    return cancellablePromise(
      fetch(finalUrl, {
        headers: { ...this.headers, ...defaultHeaders },
      }).then(async (response) => {
        await this.checkStatus(response);
        return await response.json();
      })
    );
  };

  post = <T>(
    url: string,
    body: any | FormData = {},
    baseRequestInit?: RequestInit,
    method = "POST",
    shouldStringify = true
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;
    const init: RequestInit = {
      method,
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
      },
      body: shouldStringify ? JSON.stringify(body) : body,
      ...baseRequestInit,
    };

    return cancellablePromise(
      fetch(finalUrl, init).then(async (response) => {
        await this.checkStatus(response);
        try {
          return await response.json();
        } catch {
          return;
        }
      })
    );
  };

  delete = <T>(
    url: string,
    body: any = {},
    baseRequestInit?: RequestInit
  ): { promise: Promise<T>; cancel: () => void } => {
    return this.post<T>(url, body, baseRequestInit, "DELETE");
  };

  private checkStatus = async (response: Response) => {
    if (response.status === HTTP_STATUS.SUCCESS) {
      return;
    }

    const { error = DEFAULT_ERROR_MESSAGE } = await response.json();
    throw new Error(error);
  };
}

export default MasterService;
