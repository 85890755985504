import React from "react";

interface IProps {
  color?: string;
  size?: number;
}

const DEFAULT_COLOR = "#000";
const DEFAULT_SIZE = 25;

const Separator: React.FC<IProps> = (props: IProps) => {
  const { color = DEFAULT_COLOR, size = DEFAULT_SIZE } = props;

  return (
    <div
      className="separator-container"
      style={{
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: color,
        paddingTop: size,
        marginBottom: size
      }}
    />
  );
};

export default Separator;
