import SafetySpacing from "layouts/SafetySpacing";
import React from "react";

interface IProps {
  content: string;
}

const SEPARATOR = " ";

const Heading: React.FC<IProps> = (props: IProps) => {
  const { content } = props;
  const [firstWords, ...otherWords] = content.split(SEPARATOR);

  return (
    <SafetySpacing active={false}>
      <div className="heading-title">
        <span
          className="heading-title--main"
        >
          {firstWords}
        </span>
        &nbsp;
        <span className="heading-title--sub">
          {otherWords.join(SEPARATOR)}
        </span>
      </div>
    </SafetySpacing>
  );
};

export default Heading;
