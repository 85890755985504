import React from "react";

import { IFloatingCalendarItem } from "fullComponents/FloatingCalendar";
import Link from "components/Link";
import { extractMonth, extractDayOfMonth } from "utils/date/date.utils";
import moment from "moment";

interface IProps {
  item: IFloatingCalendarItem;
}

const CalendarItem: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  const date = moment(item.date).toDate();
  const day = extractDayOfMonth(date);
  const monthName = extractMonth(date, true);

  return (
    <div className="floating-calendar-item">
      <div className="floating-calendar-item-date">
        <span>{day}</span>
        <span>{monthName}</span>
      </div>

      <div>
        <span className="floating-calendar-item-type">{item.type}</span>
        <span className="floating-calendar-item-description">
          {item.description}
        </span>

        <Link onClick={item.onClick} title={item.description} />
      </div>
    </div>
  );
};

export default CalendarItem;
