import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

const Body: React.FC<IProps> = (props: IProps) => {
  const { children } = props;
  return <div className="modal__content">{children}</div>;
};

export default Body;
