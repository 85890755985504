import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { INewsProperty } from "fullComponents/News";
import { cutString, getCDN, stripHtml } from "utils/string/string.utils";

interface IProps {
  onClick: (aNewsId: string) => void;
  item: INewsProperty;
}

const MAX_DESCRIPTION_LENGTH = 230;

const Page: React.FC<IProps> = (props: IProps) => {
  const {
    item: { id, title, shortContent, image },
  } = props;
  const imageUrl = useMemo(
    () => (image.includes("http") ? image : `${getCDN()}${image}`),
    [image]
  );

  const handleOnClick = (id: string) => {
    window.open("actualite/" + id, "_blank");
  };

  return (
    <div className="carousel-item">
      <img className="img" src={imageUrl} alt={title} />
      <div className="carousel-item-infos">
        <div className="carousel-item-title">{title}</div>
        {shortContent && (
          <p className="carousel-item-content">
            {cutString(
              stripHtml(shortContent.replace(/(\/\w+>)/g, "$1 ")),
              MAX_DESCRIPTION_LENGTH
            )}
          </p>
        )}

        {
          <div className="carousel-item--button">
            <a onClick={() => handleOnClick(id)}>
              En savoir plus <FontAwesomeIcon icon={faChevronRight as any} />
            </a>
          </div>
        }
      </div>
    </div>
  );
};

export default Page;
