import React, { CSSProperties } from "react";
import SafetySpacing from "layouts/SafetySpacing";

interface IProps {
  url: string;
  style: CSSProperties;
}

const GoogleDrive: React.FC<IProps> = (props: IProps) => {
  const { url, style } = props;

  return (
    <SafetySpacing>
      <div className="gdrive-container full-size" style={style}>
        <iframe title={url} className="full-size" src={url} />
      </div>
    </SafetySpacing>
  );
};

export default GoogleDrive;
