import { IEditorField } from "utils/editor/components.fields";
import getDefaultEditableFields from "core/types/editorDefaultFields";

const fields: IEditorField[] = [
  {
    id: "src",
    label: "Image depuis la galerie",
    type: "select_image",
    required: true,
  },
  {
    id: "src",
    label: "Image avec une URL (https)",
    type: "text",
    required: true,
  },
  {
    id: "ariaLabel",
    label: "Texte alternatif",
    type: "text",
    required: true,
  },
  ...getDefaultEditableFields(["height", "backgroundPosition"]),
];

export const FLEXIMAGE_DEFAULT_PROPS = {
  src: "https://via.placeholder.com/350x150?text=Votre+image",
  size: "contain",
  ariaLabel: "Texte alternatif",
  "style.height": 150,
};

export default fields;
