import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const EmptyMessage: React.FC = () => {
  return (
    <div className="album--empty u-center">
      <FontAwesomeIcon icon={faInfoCircle as any} />
      &nbsp;
      {`Aucun album à afficher pour le moment. Ajouter une galerie photos en éditant cet élément.`}
    </div>
  );
};

export default EmptyMessage;
