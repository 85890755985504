import React, { ReactNode } from "react";

interface IProps {
  title: string;
  children: ReactNode;
}

// Template: http://www.ffbad.org/la-ffbad/son-organisation/conseil-d-administration-bureau-federal/
const Section: React.FC<IProps> = (props: IProps) => {
  const { title, children } = props;

  return (
    <section className="bureau-section">
      <header>
        <div className="bureau-section-title">{title}</div>
      </header>
      {children}
    </section>
  );
};

export default Section;
