import { IEditorField } from "index";

type EditableFields = "width" | "height" | "backgroundPosition" | "textAlign" | "justifyContent";

const SELECT_ITEMS_POSITIONS = [
  { key: "left", label: "Gauche" },
  { key: "center", label: "Centre" },
  { key: "right", label: "Droite" }
];

const SELECT_ITEMS_POSITIONS_JUSTIFYCONTENT = [
  { key: "flex-start", label: "Gauche" },
  { key: "center", label: "Centre" },
  { key: "flex-end", label: "Droite" }
];

const FIELDS: IEditorField[] = [
  {
    id: "style.width",
    label: "Largeur fixe (optionel)",
    type: "number",
    min: 0,
    required: false
  },
  {
    id: "style.height",
    label: "Hauteur fixe (optionel)",
    type: "number",
    min: 0,
    required: false
  },
  {
    id: "style.backgroundPosition",
    label: "Alignement (optionel)",
    type: "select",
    selectItems: SELECT_ITEMS_POSITIONS,
    required: false
  },
  {
    id: "style.textAlign",
    label: "Alignement (optionel)",
    type: "select",
    selectItems: SELECT_ITEMS_POSITIONS,
    required: false
  },
  {
    id: "style.justifyContent",
    label: "Alignement (optionel)",
    type: "select",
    selectItems: SELECT_ITEMS_POSITIONS_JUSTIFYCONTENT,
    required: false
  }
];

const getDefaultEditableFields = (
  keys: EditableFields[] = []
): IEditorField[] => {
  const output: IEditorField[] = [];

  keys.forEach(key => {
    const aField = FIELDS.find(field => field.id === `style.${key}`);
    if (aField) {
      output.push(aField);
    }
  });

  return output;
};

export default getDefaultEditableFields;
