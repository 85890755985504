import React, { useEffect, useRef, useState } from "react";

import NewsService from "service/news/news.service";
import { useWebsiteId } from "hooks";
import { INewsProperty } from "fullComponents/News";
import Heading from "./components/Heading";
import Content from "./components/Content";

interface IProps {
  aNewsId: string;
  onBack: () => void;
}

const DisplayNews: React.FC<IProps> = (props: IProps) => {
  const { aNewsId, onBack } = props;
  const [news, setNews] = useState<INewsProperty>();
  const [loading, setLoading] = useState(true);
  const service = useRef(new NewsService());
  const websiteId = useWebsiteId();

  useEffect(() => {
    const { promise, cancel } = service.current.find(websiteId, aNewsId);
    promise
      .then((response) => {
        setNews(response);
        setLoading(false);
      })
      .catch(() => {
        onBack();
      });
    return () => cancel();
  }, [aNewsId]);

  return (
    <div className="display-news">
      {!loading && (
        <>
          <Heading image={news?.image} />
          <Content aNews={news} onBack={onBack} />
        </>
      )}
    </div>
  );
};

export default DisplayNews;
