import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import InstanceService from "service/instance";
import useInstanceId from "hooks/useInstanceId";
import Loader from "../Loader";

const service = new InstanceService();

export interface IEquipmentData {
  equipmentId: string;
  name: string;
  address: any;
  latitude: string;
  longitude: string;
}

export interface IEquipmentResponse {
  equipments: IEquipmentData[];
}

interface IProps {
  setLatitude?: (latitude: any) => void;
  setLongitude?: (longitude: any) => void;
  isSlotsEquipments?: boolean;
}

const Equipements: React.FC<IProps> = (props: IProps) => {
  const { setLatitude, setLongitude, isSlotsEquipments = false } = props;
  const [equipmentsData, setEquipmentsData] = useState<IEquipmentData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const instanceId = useInstanceId();

  const handleOnSelectEquipment = (equipment: any) => {
    if (setLatitude && setLongitude) {
      setLatitude(equipment.latitude);
      setLongitude(equipment.longitude);
    }
  };

  useEffect(() => {
    const { promise, cancel } = service.equipments(instanceId);
    promise.then((response) => {
      setEquipmentsData(response.equipments);
      setIsLoading(false);
    });
    return () => cancel();
  }, [instanceId]);

  return (
    <div className="equipements-container">
      {isLoading && <Loader />}

      {!isLoading &&
        equipmentsData &&
        Object.keys(equipmentsData).length !== 0 && (
          <>
            <table style={{ width: "100%" }}>
              <thead className="equipments-header">
                <tr>
                  <th className="header-element">Nom de l'installation</th>
                  <th className="header-element">Adresse</th>
                  <th className="header-element">Géolocalisation</th>
                </tr>
              </thead>

              <tbody>
                {equipmentsData.map(
                  (equipment) =>
                    (equipment.longitude !== null ||
                      equipment.latitude !== null ||
                      equipment.address.length !== 0 ||
                      equipment.name !== "") && (
                      <tr key={equipment.equipmentId}>
                        <td className="content-element">
                          {equipment.name || <p className="empty-field">-</p>}
                        </td>
                        <td className="content-element">
                          {equipment.address.address || (
                            <p className="empty-field">-</p>
                          )}
                        </td>
                        {isSlotsEquipments ? (
                          <td
                            className="content-element"
                            onClick={() => handleOnSelectEquipment(equipment)}
                          >
                            Voir sur la carte &nbsp;
                            <FontAwesomeIcon icon={faMapMarkerAlt as any} />
                          </td>
                        ) : (
                          <td
                            className="content-element"
                            onClick={() => handleOnSelectEquipment(equipment)}
                          >
                            <a
                              href={`https://maps.google.com/?q=${equipment.latitude},${equipment.longitude}&z=3`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Géolocaliser
                            </a>
                            &nbsp;
                            <FontAwesomeIcon icon={faChevronRight as any} />
                          </td>
                        )}
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </>
        )}
    </div>
  );
};

export default Equipements;
