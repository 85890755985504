import React, { CSSProperties, ReactNode } from "react";

import { buildClassName } from "../../utils/dom/dom.utils";
import SafetySpacing from "layouts/SafetySpacing";
import { BUTTON_DEFAULT_PROPS } from "./editor.fields";

type Props = {
  children: ReactNode;
  type?: "primary" | "secondary" | "leftMenu";
  disabled?: boolean;
  onClick?: (url: string) => void;
  buttonType?: "button" | "reset" | "submit";
  style?: CSSProperties;
  safetySpacing?: boolean;
  isEditor?: boolean;
  preventDefault?: boolean;
  block?: boolean;
  title?: string;
  url?: string;
  align?: "left" | "center" | "right";
};

const Button: React.FC<Props> = (props: Props) => {
  const {
    children = BUTTON_DEFAULT_PROPS.children,
    type = BUTTON_DEFAULT_PROPS.type,
    disabled = BUTTON_DEFAULT_PROPS.disabled,
    onClick,
    buttonType = BUTTON_DEFAULT_PROPS.buttonType,
    style = {},
    safetySpacing = true,
    isEditor = false,
    preventDefault = true,
    block = true,
    title,
    url,
    align = "left",
  } = props;
  const className = buildClassName("editor-button", [
    [type !== undefined, `editor-button--${type}`],
    [isEditor, "editor-button--editor-mode"],
    [block, "editor-button--block"],
  ]);

  const handleOnClick = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (e && preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (onClick) {
      onClick(url || "");
    }
    if (url !== undefined) {
      window.open(url, "_blank");
    }
  };

  const btn = (
    <button
      className={className}
      onClick={handleOnClick}
      type={buttonType}
      disabled={disabled}
      title={title}
      style={{
        width: style.width && style.width > 0 ? style.width : "fit-content",
      }}
    >
      {children}
    </button>
  );

  return (
    <SafetySpacing active={safetySpacing}>
      <div
        style={{
          display: "flex",
          justifyContent: align,
          width: "100%",
        }}
      >
        {Object.keys(style).length !== 0 ? <div style={style}>{btn}</div> : btn}
      </div>
    </SafetySpacing>
  );
};

export default Button;
