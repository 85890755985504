import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "title",
    label: "Titre",
    type: "text",
    required: false,
  },
];

export const QUICKDOCUMENTACCESS_DEFAULT_PROPS = {
  title: "Tes documents utiles",
};

export default fields;
