import React, { useEffect, useState } from "react";

import { LeaderFunctionTypes, ILeader } from "core/interfaces/api.interfaces";
import useInstanceId from "hooks/useInstanceId";
import InstanceService from "service/instance";
import Skeleton from "components/Skeleton";
import Section from "./components/Section";
import DisplayLeader from "./components/DisplayLeader";
import { BUREAU_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  displayPicture?: boolean;
}

const service = new InstanceService();

interface IRow {
  label: string;
  types: string[];
}

export interface IBoardStaffResponse {
  Board: ILeader[];
  Staff: ILeader[]
}

const ROWS: IRow[] = [
  {
    label: "Bureau",
    types: ["Président", "Vice-Président", "Secrétaire", "Trésorier"],
  },
  { label: "Dirigeant", types: ["Dirigeant"] },
  { label: "Encadrants", types: ["Resp. Inscription", "Resp. EFB", "Encadrant", "Gest. Inscription"] },
];

const Bureau: React.FC<IProps> = (props: IProps) => {
  const { displayPicture = BUREAU_DEFAULT_PROPS.displayPicture } = props;
  const [leaders, setLeaders] = useState<ILeader[]>([]);
  const [boardLeaders, setBoardLeaders] = useState<ILeader[]>([]);
  const [councilLeaders, setCouncilLeaders] = useState<ILeader[]>([]);
  const [officerLeaders, setOfficerLeaders] = useState<ILeader[]>([]);
  const [loading, setLoading] = useState(true);
  const instanceId = useInstanceId();

  useEffect(() => {
    const { promise, cancel } = service.leaders(instanceId);
    promise
      .then((response) => {
        setLeaders([...response.Board, ...response.Staff])
      })
      .catch()
      .finally(() => setLoading(false));
    return () => cancel();
  }, [instanceId]);

  useEffect(() => {
    setBoardLeaders(cleanLeadersTab(leaders.filter((leader) => ROWS[0].types.includes(leader.function))));
    setCouncilLeaders(cleanLeadersTab(leaders.filter((leader) => ROWS[1].types.includes(leader.function))));
    setOfficerLeaders(cleanLeadersTab(leaders.filter((leader) => ROWS[2].types.includes(leader.function))));
  }, [leaders]);

  const getLeadersByCategory = (category: string) => {
    switch (category) {
      case ROWS[0].label:
        return boardLeaders;
      case ROWS[1].label:
        return councilLeaders;
      case ROWS[2].label:
        return officerLeaders;
      default:
        return [];
    }
  }

  /**
   * This method remove all duplicate Ileader and replace them with an unique Ileader which has the combined functions
   * @param leadersTab array of Ileader to clean
   * @returns an array of Ileader
   */
  const cleanLeadersTab = (leadersTab: ILeader[]): ILeader[] => {
    const tmpTab = leadersTab;
    const leadersToAdd: ILeader[] = [];
    for (let i = 0; i < tmpTab.length; ++i) {
      for (let j = 0; j < tmpTab.length; ++j) {
        if (i !== j) {
          if (tmpTab[i].person.personId === tmpTab[j].person.personId) {
            leadersToAdd.push({ ...tmpTab[i], function: `${tmpTab[i].function}/${tmpTab[j].function}` })
            tmpTab.splice(j, 1);
            tmpTab.splice(i, 1);
          }
        }
      }
    }
    if (leadersToAdd.length === 0) {
      return tmpTab;
    } else {
      return cleanLeadersTab([...tmpTab, ...leadersToAdd]);
    }
  }


  return (
    <section className="bureau-container">
      <div className="bureau-sections-container">
        {ROWS.map((row) => (
          leaders.filter((leader) => row.types.includes(leader.function)).length > 0 &&
          <Section key={row.label} title={row.label}>
            {loading && <Skeleton shape="paragraph" repeat={4} />}

            {!loading && (
              <div className="bureau-leaders">
                {getLeadersByCategory(row.label)
                  .map((leader) => (
                    <DisplayLeader
                      key={leader.function + leader.leaderId}
                      leader={leader}
                      displayPicture={displayPicture}
                    />
                  ))}
              </div>
            )}
          </Section>
        ))}
      </div>
    </section>
  );
};

export default Bureau;
