import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  children?: ReactNode;
}

const Loading: React.FC<IProps> = (props: IProps) => {
  const { children = "Chargement en cours..." } = props;

  return (
    <div className="loading-container">
      {children}
      <FontAwesomeIcon icon={faSpinner as any} pulse />
    </div>
  );
};

export default Loading;
