import React from "react";
import Skeleton from "components/Skeleton";

const SkeletonNews: React.FC = () => {
  return (
    <div className="skeleton-news-container">
      <div className="skeleton-news-container--picture">
        <Skeleton shape="picture" />
      </div>

      <div className="skeleton-news-container--content">
        <Skeleton shape="title" />
        <Skeleton shape="paragraph" repeat={3} />
      </div>
    </div>
  );
};

export default SkeletonNews;
