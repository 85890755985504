import React, { ReactNode, Fragment } from "react";
import PageBuilder, {
  IPageComponentProperties,
  ComponentTypes,
} from "../pageBuilder";
import { randomString } from "utils/string/string.utils";
import COMPONENTS_MAPPING from "../components.mapping";
import EditorWrapper from "../wrapper/wrapper.editor";
import { findChildOfComponent, oneChildrenIsSection } from "../utils/editor.utils";

export interface IBuildOptions {
  wrapper?: React.FC<any>;
  wrapperProps?: any;
}

class EditorBuilder {
  private editor: PageBuilder;
  public wrapper: EditorWrapper;
  private typesToClean: ComponentTypes[] = [ComponentTypes.AddElement];

  constructor(editor: PageBuilder) {
    this.editor = editor;
    this.wrapper = new EditorWrapper(editor);
  }

  get options(): IBuildOptions {
    return this.editor.options;
  }

  get components(): IPageComponentProperties[] | undefined {
    return this.editor.properties.components;
  }

  set components(value: IPageComponentProperties[] | undefined) {
    this.editor.properties.components = value;
  }

  public build = (): ReactNode[] => {
    const components = this.components || [];
    const topLevelComponents = components.filter(
      (component) => component.type === ComponentTypes.TopLevel
    );

    const output = topLevelComponents.map((component) => this.generateComponentByType(component));
    if (this.editor.properties.menuSide === 'left') {
      const header = components.find(component => component.type === ComponentTypes.Header);
      const leftMenu = { id: 'LeftMenu', type: ComponentTypes.LeftMenu, props: { ...header?.props } };
      const leftMenuNode = this.generateComponentByType(leftMenu)
      const footer = components.find(component => component.type === ComponentTypes.Footer);
      if (footer) {
        footer.parentId = undefined;
      }
      const footerNode = footer ? this.generateComponentByType(footer, true) : <></>;
      const multipleColumnsLayout = { id: 'MCL', type: ComponentTypes.MultipleColumnsLayout, props: { firstChildNoFlex: true, children: [leftMenuNode, output] } };
      const node = this.generateComponentByType(multipleColumnsLayout)
      return [node, footerNode]
    }
    return output;
  };

  public cleanForPublish = (): IPageComponentProperties[] => {
    let newComponents = [...(this.components || [])];

    newComponents = newComponents.filter(
      (component) => !this.typesToClean.includes(component.type)
    );

    // Clean single element on layout.
    const containerTypes = [
      ComponentTypes.MultipleColumnsLayout,
      ComponentTypes.MultipleColumnsLayoutOneBig,
    ];

    newComponents
      // Find only by types.
      .filter((aComponent) => containerTypes.includes(aComponent.type))
      // Get container and related children if any.
      .map((container) => ({
        container,
        children: findChildOfComponent(container.id, newComponents),
      }))
      // Don't keep section01 & item with more than one children.
      .filter(
        (item) => !item.container.id.includes("section01") && item.children.length <= 1 && !oneChildrenIsSection(item.children, containerTypes)
      )
      .forEach((item) => {
        if (item.children.length) {
          const [aChild] = item.children;
          const tempChild = newComponents.find((p) => p.id === aChild.id);

          if (tempChild) {
            // Auto update with reference. Don't remove this part.
            tempChild.parentId = item.container.parentId;
          }
        }

        // Remove the empty "layout" component from listing.
        newComponents = newComponents.filter(
          (aComponent) => aComponent.id !== item.container.id
        );
      });

    return newComponents;
  };

  private generateComponentByType = (
    component: IPageComponentProperties,
    forceGeneration: boolean = false
  ): ReactNode => {
    const { id, type, props = {} } = component;
    const Component = this.findComponentByType(type);
    let firstParentType: ComponentTypes | undefined = undefined;

    if (this.components) {
      const parent = this.components.find((p) => p.id === component.parentId);
      if (parent && (parent.parentId !== 'top' && parent.id !== 'top')) {
        let currentParent = parent as IPageComponentProperties | undefined;
        while (currentParent?.parentId !== 'top') {
          currentParent = this.components.find((p) => p.id === currentParent?.parentId && p.props !== undefined);
        }
        firstParentType = currentParent.type;
      } else {
        firstParentType = parent?.type;
      }
    }

    if (!Component) {
      return <></>;
    }
    if (this.editor.properties?.menuSide === 'left') {
      if (!forceGeneration) {
        if (type === ComponentTypes.Footer || type === ComponentTypes.Header) {
          return <></>
        }
      }
    }
    const children = props.children || this.generateChildrenFor(component.id);
    const allProps = {
      key: randomString(),
      ...props,
      children,
    };

    const output = <Component {...allProps} />;
    return this.wrapper.wrap(output, type, firstParentType, id, this.options);
  };

  private generateChildrenFor = (
    componentId: string
  ): ReactNode[] | string | undefined => {
    const potentialChildren = this.components?.filter(
      ({ parentId }) => parentId === componentId
    );
    return potentialChildren?.map((child) => this.generateComponentByType(child));
  };

  private findComponentByType = (type: ComponentTypes) => {
    if (type === ComponentTypes.TopLevel) {
      return Fragment;
    }

    return COMPONENTS_MAPPING[type];
  };
}

export default EditorBuilder;
