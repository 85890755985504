import MasterService from "service/master.service";
import { IInstanceFull, ILeader } from "core/interfaces/api.interfaces";
import { IQuickAccessLink } from "fullComponents/QuickAccess";
import { IRankingData } from "fullComponents/Rankings";
import { IFloatingCalendarItem } from "fullComponents/FloatingCalendar";
import { IInterclubTeam, IPrice } from "service/service.interfaces";
import { IMeetingData } from "fullComponents/Meetings/meetings.interfaces";
import { ISlotsData } from "fullComponents/Slots";
import { IEquipmentData, IEquipmentResponse } from "fullComponents/Equipments";
import { IBoardStaffResponse } from "fullComponents/Bureau";

class InstanceService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  public full = (
    instanceId: number | string
  ): { promise: Promise<IInstanceFull>; cancel: () => void } => {
    const instanceFromLocalStorage = JSON.parse(
      localStorage.getItem("instance") || "{}"
    ) as IInstanceFull;

    if (instanceFromLocalStorage?.instanceId === instanceId) {
      return {
        promise: Promise.resolve(instanceFromLocalStorage),
        cancel: () => {
          // Nothing.
        },
      };
    }

    localStorage.removeItem("instance");

    const output = this.master.get<IInstanceFull>(
      `poona/instance/${instanceId}/full`
    );

    output.promise.then((instance) => {
      localStorage.setItem("instance", JSON.stringify(instance));
    });

    return output;
  };

  public teams = (
    instanceId: number | string
  ): { promise: Promise<IInterclubTeam[]>; cancel: () => void } => {
    return this.master.get<IInterclubTeam[]>(
      `poona/instance/${instanceId}/teams`
    );
  };

  public leaders = (
    instanceId: number | string
  ): { promise: Promise<IBoardStaffResponse>; cancel: () => void } => {
    return this.master.get<IBoardStaffResponse>(
      `poona/instance/${instanceId}/leaders`
    );
  };

  public activities = (
    instanceId: number | string,
    options: { view: "month" | "week" | "day"; date: string }
  ) => {
    const params = Object.keys(options).reduce(
      (prev, curr) => `${prev}&${curr}=${options[curr]}`,
      ""
    );

    return this.master.get<ILeader[]>(
      `poona/instance/${instanceId}/activities?${params}`
    );
  };

  public rankings = (
    instanceId: number | string
  ): {
    promise: Promise<IRankingData[]>;
    cancel: () => void;
  } => {
    return this.master.get<IRankingData[]>(
      `poona/instance/${instanceId}/rankings`
    );
  };

  public meetings = (
    instanceId: number | string
  ): {
    promise: Promise<IMeetingData[]>;
    cancel: () => void;
  } => {
    return this.master.get<IMeetingData[]>(
      `poona/instance/${instanceId}/meetings`
    );
  };

  public quickAccess = (
    instanceId: number | string
  ): {
    promise: Promise<IQuickAccessLink[]>;
    cancel: () => void;
  } => {
    return this.master.get<IQuickAccessLink[]>(
      `poona/instance/${instanceId}/quickAccess`
    );
  };

  public quickDocumentAccess = (
    instanceId: number | string
  ): {
    promise: Promise<IQuickAccessLink[]>;
    cancel: () => void;
  } => {
    return this.master.get<IQuickAccessLink[]>(
      `poona/instance/${instanceId}/quickDocumentAccess`
    );
  };

  public nextEvents = (
    instanceId: number | string
  ): {
    promise: Promise<IFloatingCalendarItem[]>;
    cancel: () => void;
  } => {
    return this.master.get<IFloatingCalendarItem[]>(
      `poona/instance/${instanceId}/events`
    );
  };

  public prices = (
    instanceId: number | string
  ): {
    promise: Promise<IPrice[]>;
    cancel: () => void;
  } => {
    return this.master.get<IPrice[]>(`poona/registration/prices/${instanceId}`);
  };

  public slots = (
    instanceId: number | string
  ): {
    promise: Promise<ISlotsData[]>;
    cancel: () => void;
  } => {
    return this.master.get<ISlotsData[]>(`poona/instance/${instanceId}/slots`);
  };

  public equipments = (
    instanceId: number | string
  ): {
    promise: Promise<IEquipmentResponse>;
    cancel: () => void;
  } => {
    return this.master.get<IEquipmentResponse>(
      `poona/instance/${instanceId}/equipments`
    );
  };

  public equipmentsById = (
    equipmentId: number | string
  ): {
    promise: Promise<IEquipmentData>;
    cancel: () => void;
  } => {
    return this.master.get<IEquipmentData>(`poona/equipments/${equipmentId}`);
  };

  public addToNewsletter = (
    instanceId: number | string,
    email: string
  ): {
    promise: Promise<any>;
    cancel: () => void;
  } => {
    return this.master.post<any>(
      `poona/instance/${instanceId}/newsletter/add`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public removeFromNewsletter = (
    instanceId: number | string,
    email: string
  ): {
    promise: Promise<any>;
    cancel: () => void;
  } => {
    return this.master.delete<any>(
      `poona/instance/${instanceId}/newsletter/remove`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
}

export default InstanceService;
