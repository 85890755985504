import PageBuilder from "../pageBuilder";
import { findComponentById } from "../utils/editor.utils";
import { IPageComponentProperties } from "index";

class EditorReplacer {
  private editor: PageBuilder;

  constructor(editor: PageBuilder) {
    this.editor = editor;
  }

  get components(): IPageComponentProperties[] | undefined {
    return this.editor.properties.components;
  }

  public replaceComponentById = (
    componentId: string,
    newComponent: IPageComponentProperties
  ): IPageComponentProperties | undefined => {
    if (!newComponent) {
      return;
    }

    const aComponent = findComponentById(componentId, this.components);

    if (!aComponent) {
      console.error("[EDITOR|REPLACE] Unable to find component", {
        componentId,
      });
      return;
    }

    aComponent.id = newComponent?.id;
    aComponent.type = newComponent?.type;
    aComponent.props = newComponent?.props;
    return aComponent;
  };
}

export default EditorReplacer;
