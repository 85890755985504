import { IEditorField } from "utils/editor/components.fields";
import getDefaultEditableFields from "core/types/editorDefaultFields";

const fields: IEditorField[] = [
  {
    id: "content",
    label: "Titre",
    type: "text",
    required: true,
  },
  ...getDefaultEditableFields(["textAlign"]),
];

export const TITLE_DEFAULT_PROPS = {
  content: "Saisissez votre titre",
};

export default fields;
