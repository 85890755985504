import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { isAccessibleButtonKeys, buildClassName } from "utils/dom/dom.utils";

interface IProps {
  label: string;
  onClick: () => void;
  style: CSSProperties;
  light?: boolean;
  showOnHover?: boolean;
}

const DEFAULT_LABEL = "Ajouter un élément";

const AddElement: React.FC<IProps> = (props: IProps) => {
  const {
    label = DEFAULT_LABEL,
    onClick,
    style,
    light = false,
    showOnHover = false,
  } = props;
  const className = buildClassName("add-element-container", [
    [light, "light"],
    [showOnHover, "show-on-hover"],
  ]);

  return (
    <div className={className} title={label} style={style}>
      <div
        className="content"
        onClick={onClick}
        onKeyDown={(e) => {
          if (isAccessibleButtonKeys(e)) {
            onClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="add-element-button">
          <span className="icon">
            <FontAwesomeIcon icon={faPlus as any} size={light ? "1x" : "2x"} />
          </span>
          {!light && <span className="text">{label}</span>}
        </div>
      </div>
    </div>
  );
};

export default AddElement;
