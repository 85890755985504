import React, { ReactNode } from "react";

import { buildClassName } from "utils/dom/dom.utils";

interface IProps {
  className?: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

const FlexContent: React.FC<IProps> = (props: IProps) => {
  const { className, children, style } = props;
  const mainClassName = buildClassName("flex-content", [[className]]);

  return (
    <div className={mainClassName} style={style}>
      {children}
    </div>
  );
};

export default FlexContent;
