import { IInstanceFull } from "core/interfaces/api.interfaces";
import { useInstanceId } from "hooks";
import React, { CSSProperties, useEffect, useState } from "react";
import InstanceService from "service/instance";

interface IProps {
  style?: CSSProperties;
}

const service = new InstanceService();

const CustomFooterContact: React.FC<IProps> = (props: IProps) => {
  const { style, } = props;
  const [instance, setInstance] = useState<IInstanceFull>();
  const [loading, setLoading] = useState(true);
  const instanceId = useInstanceId();

  useEffect(() => {
    const { promise, cancel } = service.full(instanceId);
    promise
      .then(setInstance)
      .catch()
      .finally(() => setLoading(false));
    return () => cancel();
  }, [instanceId]);

  return (
    <div style={style}>
      <h2 className="footer-main--heading">Contact du club</h2>

      <h3 className="footer-main--heading-2">Adresse mail</h3>
      <p className="footer-main--text">{instance?.email ? instance?.email : 'Aucun mail'}</p>

      <h3 className="footer-main--heading-2">Numéro de téléphone</h3>
      <p className="footer-main--text">{instance?.mobile ? instance?.mobile : 'Aucun numéro'}</p>
    </div>
  );
};

export default CustomFooterContact;
