import React, { useEffect, useState } from "react";
import Slots from "fullComponents/Slots";
import { SLOTS_DEFAULT_PROPS, EQUIPMENTS_DEFAULT_PROPS } from "./editor.fields";
import Equipements, { IEquipmentData } from "../Equipments";
import MapComponent from "./components/MapComponent";
import InstanceService from "service/instance";
import { useInstanceId } from "hooks";

const service = new InstanceService();

const SlotsEquipments: React.FC = () => {
    const [currentTabId, setCurrentTabId] = useState(0);
    const [equipmentsData, setEquipmentsData] = useState<IEquipmentData[]>([]);
    const instanceId = useInstanceId();
    const [latitude, setLatitude] = useState<any>(0);
    const [longitude, setLongitude] = useState<any>(0);
    const [centerLatitude, setCenterLatitude] = useState<any>(0);
    const [centerLongitude, setCenterLongitude] = useState<any>(0);

    const getCenter = (equipments: IEquipmentData[]) => {
        let x = 0;
        let y = 0;
        let nbEquipments = 0;
        equipments.forEach((equipment: any) => {
            if (equipment.latitude !== null && equipment.longitude !== null) {
                ++nbEquipments;
                x += parseFloat(equipment.latitude);
                y += parseFloat(equipment.longitude);
            }
        });
        setLatitude(x / nbEquipments);
        setLongitude(y / nbEquipments);
        setCenterLatitude(x / nbEquipments);
        setCenterLongitude(y / nbEquipments);
    }

    const tab = [SLOTS_DEFAULT_PROPS.title, EQUIPMENTS_DEFAULT_PROPS.title]

    const handleTabClick = (id: number) => {
        if (id === currentTabId) return;
        setCurrentTabId(id)
    }

    const handleCenterMapClick = (equipments: IEquipmentData[]) => {
        getCenter(equipments);
    }

    useEffect(() => {
        const { promise, cancel } = service.equipments(instanceId);
        promise.then((response) => {
            setEquipmentsData(response.equipments);
            getCenter(response.equipments);
        });
        return () => cancel();
    }, [instanceId]);

    return (
        <div className="slotsEquipments-container">
            <div className="button-container">
                {tab.map((tab, index) =>
                    <div
                        className={currentTabId === index ?
                            'button-primary-light active bg-primary-color' :
                            'button-primary-light'}
                        key={index}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab}
                    </div>
                )}
            </div>

            <MapComponent center={[latitude, longitude]} equipments={equipmentsData} />
            {centerLatitude !== latitude && centerLongitude !== longitude && <div className="centerMap-button" onClick={() => handleCenterMapClick(equipmentsData)}>Recentrer la carte</div>}

            {currentTabId === 0 ?
                <Slots setLatitude={setLatitude} setLongitude={setLongitude} isSlotsEquipments={true} />
                :
                <Equipements setLatitude={setLatitude} setLongitude={setLongitude} isSlotsEquipments={true} />
            }
        </div>
    );
};

export default SlotsEquipments;
