import React, { useEffect, useState } from "react";

import { IAlbum } from "service/service.interfaces";
import { useGalleryService } from "hooks";
import Button from "components/Button";

interface IProps {
  currentAlbumId: string;
  onChange: (albumId: string) => void;
}

const DisplayAlbums: React.FC<IProps> = (props: IProps) => {
  const { currentAlbumId, onChange } = props;
  const galleryService = useGalleryService();
  const [albums, setAlbums] = useState<IAlbum[]>([]);

  useEffect(() => {
    const { promise, cancel } = galleryService.all();
    promise.then(setAlbums);
    return () => cancel();
    // eslint-disable-next-line
  }, []);

  const handleOnSelect = (albumId: string) => {
    onChange(albumId);
  };

  return (
    <div className="albums-selection">
      {albums.map((album) => (album.visible &&
        <div key={album.id} className="albums-selection--item">
          <Button
            onClick={() => handleOnSelect(album.id)}
            safetySpacing={false}
            type={currentAlbumId === album.id ? "primary" : "secondary"}
          >
            {album.title}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default DisplayAlbums;
