import MasterService from "service/master.service";

class ConfigurationService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  getByOwnerId = (
    ownerId: string
  ): {
    promise: Promise<IWebsiteConfig>;
    cancel: () => void;
  } => {
    return this.master.get<IWebsiteConfig>(`websites/${ownerId}?key=ownerId`);
  };
}

export default ConfigurationService;

export interface IWebsiteConfig {
  id: string;
  ownerId: string;
  domainNames?: string[];
  configuration: {
    social?: {
      facebook?: string;
      twitter?: string;
      instagram?: string;
    };
    tonalite?: {
      key: string;
      title: string;
      color: string;
      backgroundColor: string;
    };
    menu: {
      side: "top" | "left";
      genericPages?: {
        news: boolean;
      };
      items: {
        pageId: string;
        url: string;
        label: string;
        menu?: { pageId: string; url: string; label: string }[];
      }[];
    };
    colors: { primary: string; secondary: string; text: string };
    fonts: { family: string; size: string };
    homePage: { pageId: string; url: string; label: string };
  };
}
