import React from "react";

import { ILeader } from "core/interfaces/api.interfaces";

interface IProps {
  leader: ILeader;
  displayPicture: boolean;
}

const NO_PICTURE = "https://via.placeholder.com/124x164";

const DisplayLeader: React.FC<IProps> = (props: IProps) => {
  const { leader, displayPicture } = props;

  return (
    <section className="bureau-leader">
      {displayPicture && (
        <span
          className="bureau-leader-picture"
          style={{
            backgroundImage: `url(${leader.person.photo || NO_PICTURE})`,
          }}
        />
      )}

      <span className="bureau-leader-fullname">
        {leader.person.firstName} {leader.person.lastName}
      </span>

      <span className="bureau-leader-function">{leader.function}</span>
    </section>
  );
};

export default DisplayLeader;
