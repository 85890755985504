import React, { ReactNode } from "react";

import PageBuilder, { ComponentTypes, IBuildOptions } from "../pageBuilder";
import { randomString } from "utils/string/string.utils";

class EditorWrapper {
  private editor: PageBuilder;
  public componentsToWrap: ComponentTypes[] = [
    ComponentTypes.Button,
    ComponentTypes.FlexImage,
    ComponentTypes.GoogleDrive,
    ComponentTypes.TextEditor,
    ComponentTypes.Title,
    ComponentTypes.YouTubePlayer,
    ComponentTypes.Heading,
    //
    ComponentTypes.Carousel,
    ComponentTypes.CarouselCalendar,
    ComponentTypes.FloatingCalendar,
    ComponentTypes.InterclubResults,
    ComponentTypes.Meetings,
    ComponentTypes.News,
    ComponentTypes.QuickAccess,
    ComponentTypes.Rankings,
    ComponentTypes.SubscribeNow,
    ComponentTypes.QuickDocumentAccess,
    ComponentTypes.Bureau,
    ComponentTypes.Contact,
    ComponentTypes.InterclubTeams,
    ComponentTypes.CalendarActivity,
    ComponentTypes.Footer,
    ComponentTypes.Header,
    ComponentTypes.LeftMenu,
    ComponentTypes.Albums,
    ComponentTypes.Prices,
    ComponentTypes.Sponsors,
    ComponentTypes.SlotsEquipments,
    ComponentTypes.ConnectionForm,
    ComponentTypes.CustomFooterContact,
    ComponentTypes.CustomFooterLogo,
  ];

  constructor(editor: PageBuilder) {
    this.editor = editor;
  }

  get options(): IBuildOptions {
    return this.editor.options;
  }

  set options(values: IBuildOptions) {
    this.editor.options = values;
  }

  public wrap = (
    children: ReactNode,
    currentComponentType: ComponentTypes,
    firstParentType: ComponentTypes | undefined,
    id: string,
    options: IBuildOptions
  ): ReactNode => {
    this.options = options;
    if (
      !this.wrapperIsProvided() ||
      !this.canWrapComponent(currentComponentType)
    ) {
      return children;
    }

    const Wrapper = this.options.wrapper as React.FC;
    return (
      <Wrapper
        key={randomString()}
        {...this.options.wrapperProps}
        componentId={id}
        componentType={currentComponentType}
        firstParentType={firstParentType}
      >
        {children}
      </Wrapper>
    );
  };

  private wrapperIsProvided = (): boolean =>
    this.options && this.options.wrapper !== undefined;

  private canWrapComponent = (type: ComponentTypes): boolean =>
    this.componentsToWrap.includes(type);
}

export default EditorWrapper;
