import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { buildClassName } from "utils/dom/dom.utils";

interface IProps {
  children: any;
  type?: "primary" | "secondary" | "disabled" | "danger";
  timeInMs?: number;
  onHide?: () => void;
  isEditor?: boolean;
}

const iconFromType = (type: string): any => {
  const data: any = {
    primary: faCheck,
    secondary: faCheck,
    danger: faExclamationTriangle,
  };

  return data[type];
};

const Notification: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = useState(true);
  const {
    children,
    type = "primary",
    timeInMs = 5000,
    onHide,
    isEditor = false,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        onHide && onHide();
      }, 300);
    }, timeInMs);
  }, [onHide, timeInMs]);

  const className = buildClassName("notification-container", [
    [`notification-container--${type}`],
    [
      visible,
      "notification-container--visible",
      "notification-container--hidden",
    ],
    [isEditor, "notification-container-editor-mode"],
  ]);

  return (
    <div className={className}>
      <span className="notification-container--icon">
        <FontAwesomeIcon icon={iconFromType(type)} />
      </span>
      {children}
    </div>
  );
};

export default Notification;
