import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "title",
    label: "Titre",
    type: "text",
    required: false,
  },
  {
    id: "maxItems",
    label: "Nombre d'éléments",
    type: "number",
    min: 1,
    max: 5,
    required: true,
  },
];

export const NEWS_DEFAULT_PROPS = {
  title: "Les actualités du club",
  maxItems: 3,
};

export default fields;
