import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "albumId",
    label: "Album à afficher",
    type: "select_albums",
    selectItems: [],
    required: true,
  },
  {
    id: "canSelectAlbums",
    label: "Permettre la sélection des albums",
    type: "checkbox",
    required: false,
  },
];

export const ALBUMS_DEFAULT_PROPS = {
  albumId: "",
  canSelectAlbums: false,
};

export default fields;
