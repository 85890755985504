import { IImage, IAlbum } from "service/service.interfaces";
import MasterService from "service/master.service";
import LocalStorageService from "service/localStorage/localStorage.service";

class GalleryService {
  private master: MasterService;
  private localStorage: LocalStorageService;

  constructor() {
    this.master = new MasterService();
    this.localStorage = new LocalStorageService();
  }

  get ownerId(): string {
    return this.localStorage.get("iid") as string;
  }

  all = (): { promise: Promise<IAlbum[]>; cancel: any } => {
    return this.master.get<IAlbum[]>(`albums?ownerId=${this.ownerId}`);
  };

  find = (
    albumId: string
  ): { promise: Promise<IAlbum>; cancel: () => void } => {
    return this.master.get<IAlbum>(`albums/${albumId}`);
  };

  findPictures = (
    albumId: string
  ): { promise: Promise<IImage[]>; cancel: () => void } => {
    return this.master.get<IImage[]>(`albums/${albumId}/images`);
  };
}

export default GalleryService;
