import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "title",
    label: "Titre",
    type: "text",
    required: false,
  },
  {
    id: "postalAddressLabel",
    label: "Texte de l'adresse postale",
    type: "text",
    required: false,
  },
  {
    id: "emailLabel",
    label: "Texte de l'e-mail",
    type: "text",
    required: false,
  },
  {
    id: "phoneLabel",
    label: "Texte du téléphone",
    type: "text",
    required: false,
  },
  {
    id: "hideHeader",
    label: "Masquer l'image",
    type: "checkbox",
    required: false,
  },
];

export const CONTACT_DEFAULT_PROPS = {
  title: "Contactez-nous",
  postalAddressLabel: "Adresse du club",
  emailLabel: "Envoyez-nous un e-mail",
  phoneLabel: "Appelez-nous",
  hideHeader: false,
};

export default fields;
