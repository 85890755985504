import { KeyCodes } from "core/types/keyCodes";

export const buildClassName = (
  initialClass: string,
  args: Array<Array<string | boolean | undefined>> = [[]]
): string => {
  const classNames: string[] = [initialClass];

  if (args.length) {
    args.forEach(([shouldBeAdded, trueValue, falseValue]) => {
      if (shouldBeAdded && typeof shouldBeAdded === "string" && !trueValue) {
        classNames.push(shouldBeAdded);
        return;
      }

      if (!shouldBeAdded) {
        if (falseValue) {
          classNames.push(falseValue as string);
        }

        return;
      }

      classNames.push(trueValue as string);
    });
  }

  return classNames.join(" ").trim();
};

export const isAccessibleButtonKeys = (e: any): boolean => {
  const availableKeyCodes: KeyCodes[] = [KeyCodes.ENTER, KeyCodes.SPACE];
  const output = availableKeyCodes.includes(e.keyCode);
  if (output) {
    e.preventDefault();
  }
  return output;
};
