import React, { useEffect, useState } from "react";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { IWebsiteConfig } from "service/configuration";
import Button from "components/Button";
import SocialLink from "../SocialLink";
import InstanceService from "service/instance";
import { useInstanceId } from "hooks";

interface IProps {
  websiteConfiguration: IWebsiteConfig;
}

const instanceService = new InstanceService();

const FirstPart: React.FC<IProps> = (props: IProps) => {
  const { websiteConfiguration } = props;
  const instanceId = useInstanceId();
  const [initials, setInitials] = useState<string>();

  useEffect(() => {
    const { promise } = instanceService.full(instanceId);
    promise.then((anInstance) => {
      setInitials(anInstance.initials);
    });
  }, [instanceId]);

  return (
    <>
      {websiteConfiguration.configuration.social && (
        <div className="social-buttons">
          {websiteConfiguration.configuration.social.facebook && (
            <SocialLink
              href={websiteConfiguration.configuration.social.facebook}
              icon={faFacebook as any}
            />
          )}
          {websiteConfiguration.configuration.social.twitter && (
            <SocialLink
              href={websiteConfiguration.configuration.social.twitter}
              icon={faTwitter as any}
            />
          )}
          {websiteConfiguration.configuration.social.instagram && (
            <SocialLink
              href={websiteConfiguration.configuration.social.instagram}
              icon={faInstagram as any}
            />
          )}
        </div>
      )}

      <Button
        onClick={() => {
          window.open(
            "https://myffbad.fr/recherche/joueur/" + initials + "?licence=true",
            "_blank"
          );
        }}
        type="primary"
        block={false}
        safetySpacing={false}
        style={{ minWidth: "205px" }}
      >
        voir nos joueurs
      </Button>
    </>
  );
};

export default FirstPart;
