import getDefaultEditableFields from "core/types/editorDefaultFields";
import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "htmlContent",
    label: "Contenu",
    type: "htmlContent",
    required: true
  },
  ...getDefaultEditableFields(["textAlign"]),
];

export const TEXTEDITOR_DEFAULT_PROPS = {
  content: "Saisissez votre text",
};

export default fields;
