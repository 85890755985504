export default {
  culture: "fr",
  startAccessor: "start",
  endAccessor: "end",
  views: {
    month: true,
    week: true,
    day: true,
  },
  messages: {
    allDay: "journée",
    previous: "précédent",
    next: "suivant",
    today: "aujourd'hui",
    month: "mois",
    week: "semaine",
    day: "jour",
    agenda: "Agenda",
    date: "date",
    time: "heure",
    event: "événement",
    showMore: (total: number) => `+ ${total} événement(s) supplémentaire(s)`,
  },
  formats: {
    dateFormat: "DD",
    monthHeaderFormat: "MMMM YYYY",
    dayHeaderFormat: "DD MMMM YYYY",
  },
};
