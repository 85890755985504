import { ComponentTypes } from "./components.mapping";

import ButtonFields, {
  BUTTON_DEFAULT_PROPS,
} from "components/Button/editor.fields";
import FlexImageFields, {
  FLEXIMAGE_DEFAULT_PROPS,
} from "components/FlexImage/editor.fields";
import GoogleDriveFields from "components/GoogleDrive/editor.fields";
import TextEditorFields from "components/TextEditor/editor.fields";
import TitleFields, {
  TITLE_DEFAULT_PROPS,
} from "components/Title/editor.fields";
import HeadingField, { HEADING_DEFAULT_PROPS } from "components/Heading/editor.fields"
import YouTubePlayerFields from "components/YouTubePlayer/editor.fields";

import NewsFields, {
  NEWS_DEFAULT_PROPS,
} from "fullComponents/News/editor.fields";
import InterclubResultsFields, {
  INTERCLUBRESULTS_DEFAULT_PROPS,
} from "fullComponents/InterclubResults/editor.fields";
import SubscribeNowFields, {
  SUBSCRIBENOW_DEFAULT_PROPS,
} from "fullComponents/SubscribeNow/editor.fields";
import QuickAccessFields, {
  QUICKACCESS_DEFAULT_PROPS,
} from "fullComponents/QuickAccess/editor.fields";
import QuickDocumentAccessFields, {
  QUICKDOCUMENTACCESS_DEFAULT_PROPS,
} from "fullComponents/QuickDocumentAccess/editor.fields";
import CarouselCalendarFields, {
  CAROUSELCALENDAR_DEFAULT_PROPS,
} from "fullComponents/CarouselCalendar/editor.fields";
import BureauFields, {
  BUREAU_DEFAULT_PROPS,
} from "fullComponents/Bureau/editor.fields";
import ContactFields, {
  CONTACT_DEFAULT_PROPS,
} from "fullComponents/Contact/editor.fields";
import CalendarFields, {
  CALENDARACTIVITY_DEFAULT_PROPS,
} from "fullComponents/CalendarActivity/editor.fields";
import FooterFields, {
  FOOTER_DEFAULT_PROPS,
} from "fullComponents/Footer/editor.fields";
import AlbumsFields, {
  ALBUMS_DEFAULT_PROPS,
} from "fullComponents/Albums/albums.fields";
import PricesFields, {
  PRICES_DEFAULT_PROPS,
} from "fullComponents/Prices/editor.fields";
import CarouselFields, {
  CAROUSEL_DEFAULT_PROPS
} from "fullComponents/Carousel/editor.fields"
import SponsorsFields, {
  SPONSORS_DEFAULT_PROPS
} from "fullComponents/Sponsors/editor.fields"
import CustomFooterContactFields from "fullComponents/CustomFooter/CustomFooterContact/editor.fields"
import CustomFooterLogoFields from "fullComponents/CustomFooter/CustomFooterLogo/editor.fields"

export interface IEditorField {
  id: string;
  label: string;
  placeholder?: string;
  type:
  | "text"
  | "textarea"
  | "number"
  | "checkbox"
  | "select"
  | "htmlContent"
  | "select_albums"
  | "select_image";
  min?: number;
  max?: number;
  step?: number;
  selectItems?: Array<{ key: string; label: string }>;
  required: boolean;
}

const COMPONENT_FIELDS: { [key: number]: IEditorField[] } = {
  [ComponentTypes.Button]: ButtonFields,
  [ComponentTypes.FlexImage]: FlexImageFields,
  [ComponentTypes.GoogleDrive]: GoogleDriveFields,
  [ComponentTypes.TextEditor]: TextEditorFields,
  [ComponentTypes.Title]: TitleFields,
  [ComponentTypes.Heading]: HeadingField,
  [ComponentTypes.YouTubePlayer]: YouTubePlayerFields,
  //
  [ComponentTypes.News]: NewsFields,
  [ComponentTypes.Carousel]: CarouselFields,
  [ComponentTypes.InterclubResults]: InterclubResultsFields,
  [ComponentTypes.SubscribeNow]: SubscribeNowFields,
  [ComponentTypes.QuickAccess]: QuickAccessFields,
  [ComponentTypes.QuickDocumentAccess]: QuickDocumentAccessFields,
  [ComponentTypes.CarouselCalendar]: CarouselCalendarFields,
  [ComponentTypes.Bureau]: BureauFields,
  [ComponentTypes.Contact]: ContactFields,
  [ComponentTypes.CalendarActivity]: CalendarFields,
  //[ComponentTypes.Footer]: FooterFields,
  [ComponentTypes.Albums]: AlbumsFields,
  [ComponentTypes.Prices]: PricesFields,
  [ComponentTypes.Sponsors]: SponsorsFields,
  [ComponentTypes.CustomFooterContact]: CustomFooterContactFields,
  [ComponentTypes.CustomFooterLogo]: CustomFooterLogoFields,
};

export const DEFAULT_PROPS: { [key: number]: object } = {
  [ComponentTypes.Title]: TITLE_DEFAULT_PROPS,
  [ComponentTypes.Heading]: TITLE_DEFAULT_PROPS,
  [ComponentTypes.FlexImage]: FLEXIMAGE_DEFAULT_PROPS,
  [ComponentTypes.Button]: BUTTON_DEFAULT_PROPS,
  //
  [ComponentTypes.Contact]: CONTACT_DEFAULT_PROPS,
  [ComponentTypes.Bureau]: BUREAU_DEFAULT_PROPS,
  [ComponentTypes.CarouselCalendar]: CAROUSELCALENDAR_DEFAULT_PROPS,
  [ComponentTypes.InterclubResults]: INTERCLUBRESULTS_DEFAULT_PROPS,
  [ComponentTypes.News]: NEWS_DEFAULT_PROPS,
  [ComponentTypes.Carousel]: CAROUSEL_DEFAULT_PROPS,
  [ComponentTypes.QuickAccess]: QUICKACCESS_DEFAULT_PROPS,
  [ComponentTypes.QuickDocumentAccess]: QUICKDOCUMENTACCESS_DEFAULT_PROPS,
  [ComponentTypes.SubscribeNow]: SUBSCRIBENOW_DEFAULT_PROPS,
  [ComponentTypes.CalendarActivity]: CALENDARACTIVITY_DEFAULT_PROPS,
  //[ComponentTypes.Footer]: FOOTER_DEFAULT_PROPS,
  [ComponentTypes.Albums]: ALBUMS_DEFAULT_PROPS,
  [ComponentTypes.Prices]: PRICES_DEFAULT_PROPS,
  [ComponentTypes.Sponsors]: SPONSORS_DEFAULT_PROPS,
};

export default COMPONENT_FIELDS;
