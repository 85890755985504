import React from "react";

import Title from "components/Title";

interface IProps {
  title: string;
}

const Heading: React.FC<IProps> = (props: IProps) => {
  const { title } = props;
  return (
    <div className="prices__heading u-center">
      <Title content={title} safetySpacing={false} />
    </div>
  );
};

export default Heading;
