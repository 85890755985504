import MasterService from "service/master.service";
import { IQuickAccessLink } from "fullComponents/QuickAccess";
import { IQuickDocumentAccessLink } from "fullComponents/QuickDocumentAccess";

class QuickAccesService {
    private master: MasterService;

    constructor() {
        this.master = new MasterService();
    }

    public quickAccess = (
        websiteId: number | string
    ): {
        promise: Promise<IQuickAccessLink[]>;
        cancel: () => void;
    } => {
        return this.master.get<IQuickAccessLink[]>(
            `websites/${websiteId}/useful-links`
        );
    };

    public quickDocumentAccess = (
        websiteId: number | string
    ): {
        promise: Promise<IQuickDocumentAccessLink[]>;
        cancel: () => void;
    } => {
        return this.master.get<IQuickDocumentAccessLink[]>(
            `websites/${websiteId}/documents`
        );
    };
}

export default QuickAccesService;
