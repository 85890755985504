import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onMenuItemClick: (href: string) => void;
  item: IItem;
}

interface IItem {
  pageId: string;
  url: string;
  label: string;
  menu?: { pageId: string; url: string; label: string }[];
}

const Link: React.FC<IProps> = (props: IProps) => {
  const { item, onMenuItemClick } = props;
  const hasSubItems = item.menu && item.menu.length !== 0;
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (window.location.pathname === item.url) {
      setSelected(true);
    }
  }, []);

  return (
    <li className="links--item">
      {!hasSubItems && (
        <a
          className={
            selected ? "links--item-link--current" : "links--item-link"
          }
          onClick={() => onMenuItemClick(item.url)}
          title={item.label}
        >
          {item.label.toUpperCase()}
        </a>
      )}

      {hasSubItems && (
        <>
          <span
            className={
              selected ? "links--item-link--current" : "links--item-link"
            }
          >
            {item.label.toUpperCase()} &nbsp;
            <FontAwesomeIcon
              className="links--item-link-icon"
              icon={faChevronDown as any}
            />
          </span>
          <ul className="links--sub-items">
            {item?.menu?.map((subItem: IItem, index: number) => (
              <Link
                key={index}
                item={subItem}
                onMenuItemClick={onMenuItemClick}
              />
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

export default Link;
