import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "view",
    label: "Vue par défaut",
    type: "select",
    selectItems: [
      { key: "month", label: "Mois" },
      { key: "week", label: "Semaine" },
      { key: "day", label: "Jour" },
    ],
    required: true,
  },
  {
    id: "toolbar",
    label: "Afficher les boutons",
    type: "checkbox",
    required: false,
  },
  {
    id: "step",
    label: "Interval (minutes)",
    type: "number",
    min: 30,
    step: 10,
    required: false,
  },
  {
    id: "height",
    label: "Hauteur (pixel)",
    type: "number",
    min: 200,
    required: false,
  },
];

export const CALENDARACTIVITY_DEFAULT_PROPS: {
  view: "month" | "week" | "day";
  toolbar: boolean;
  height: number;
  step: number;
} = {
  view: "month",
  toolbar: true,
  height: 500,
  step: 30,
};

export default fields;
