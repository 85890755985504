import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { IEquipmentData } from "fullComponents/Equipments";

interface IProps {
  equipment: IEquipmentData | null;
}

const LocationLink: React.FC<IProps> = (props: IProps) => {
  const { equipment } = props;

  return (
    <div>
      {equipment &&
        equipment.latitude !== null &&
        equipment.longitude !== null && (
          <div>
            <a
              href={`https://maps.google.com/?q=${equipment.latitude},${equipment.longitude}&z=3`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Géolocaliser
            </a>
            &nbsp;
            <FontAwesomeIcon icon={faChevronRight as any} />
          </div>
        )}
    </div>
  );
};

export default LocationLink;
