import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "title",
    label: "Titre",
    type: "text",
    required: false,
  },
  {
    id: "autoplay",
    label: "Défilement automatique",
    type: "checkbox",
    required: false,
  },
  {
    id: "interval",
    label: "Interval (minutes)",
    type: "number",
    min: 1,
    step: 1,
    required: false,
  },
];

export const SPONSORS_DEFAULT_PROPS: {
  title: string;
  autoplay: boolean;
  interval: number;
} = {
  title: "Nos partenaires",
  autoplay: false,
  interval: 3,
};

export default fields;
