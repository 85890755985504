import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PlayerRoleLabels } from "core/types/playerRoles";
import { IInterclubTeamPlayer } from "service/service.interfaces";
import DEFAULT_PICTURE_MALE from "../../../../assets/img/male-avatar.png";
import DEFAULT_PICTURE_FEMALE from "../../../../assets/img/female-avatar.png";

interface IProps {
  user: IInterclubTeamPlayer;
  icon?: IconDefinition;
}

const User: React.FC<IProps> = (props: IProps) => {
  const { user, icon } = props;

  const role: string = PlayerRoleLabels[user.ID_Role];
  let picture = user.Photo;

  if (!picture || !picture.includes("http")) {
    picture = user.Sexe === 1 ? DEFAULT_PICTURE_MALE : DEFAULT_PICTURE_FEMALE;
  }

  return (
    <div className="org-chart-user">
      <div className="content">
        <div
          className="org-chart-user-picture"
          style={{ backgroundImage: `url(${picture})` }}
        />
        <div className="org-chart-user-name">
          {icon && (
            <>
              <FontAwesomeIcon icon={icon as any} />
              &nbsp;
            </>
          )}
          {user.Nom} {user.Prenom}
        </div>
      </div>
      <div className="org-chart-user-role">{role}</div>
    </div>
  );
};

export default User;
