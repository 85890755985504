import React, { ReactNode } from "react";

import FlexContainer from "../FlexContainer";
import FlexContent from "../FlexContent";
import Contact from "./components/Contact";
import Logo from "./components/Logo";

interface IProps {
  children: ReactNode[];
  className?: string;
  style?: React.CSSProperties;
  firstChildNoFlex?: boolean;
  flexDirection?: "row" | "column";
  alignItems?: "flex-start" | "flex-end" | "center";
}

const CustomFooter: React.FC<IProps> = (props: IProps) => {
  const { className, children = [], style, flexDirection, alignItems, firstChildNoFlex } = props;
  const mainProps = { className, style };

  const rows: React.FC[] = [Logo, Contact];

  return (
    <div className="custom-footer" data-firstchildnoflex={firstChildNoFlex}>
      <FlexContainer
        {...mainProps}
        flexDirection={flexDirection}
        alignItems={alignItems}
      >
        {children.length > 0 ?
          children.map((element, index) => {
            return <FlexContent key={index}>{element}</FlexContent>;
          })
          :
          rows.map(
            (Component, index) => { return <FlexContent key={index}><Component /></FlexContent>; }
          )
        }
      </FlexContainer>
    </div>
  );
};

export default CustomFooter;
