import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import { useBreakpoints } from "hooks";
import CustomImageRenderer from "./components/CustomImageRenderer";

const ModalGatewayFixed: React.ComponentType<any> = ModalGateway;

export type IMansoryPhoto = {
  src: string;
  srcSet?: string | string[] | undefined;
  sizes?: string | string[] | undefined;
  width: number;
  height: number;
  alt?: string | undefined;
  key?: string | undefined;
};

type Props = {
  photos: IMansoryPhoto[];
};

// Mansory doc: http://neptunian.github.io/react-photo-gallery/api.html
// Lightroom doc: https://github.com/jossmac/react-images
const Mansory: React.FC<Props> = (props: Props) => {
  const { photos = [] } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { SM, XS } = useBreakpoints();

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const openLightbox = (photo: IMansoryPhoto, index: number) => {
    if (!photo) {
      return;
    }

    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const imageRenderer = ({ index, left, top, key, photo }: any) => {
    return (
      <CustomImageRenderer
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
        onClick={openLightbox}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Gallery
        photos={photos}
        direction={XS || SM ? "column" : "row"}
        renderImage={imageRenderer}
      />

      <ModalGatewayFixed>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((picture: any) => ({
                ...picture,
                srcset: picture.src,
                // caption: "No caption for now",
              }))}
            />
          </Modal>
        )}
      </ModalGatewayFixed>
    </div>
  );
};

export default Mansory;
