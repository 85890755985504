import MasterService from "service/master.service";

class SponsorsService {
    private master: MasterService;

    constructor() {
        this.master = new MasterService();
    }

    public sponsors = (
        websiteId: number | string
    ): {
        promise: Promise<any>;
        cancel: () => void;
    } => {
        return this.master.get<any>(
            `websites/${websiteId}/sponsors`
        );
    };
}

export default SponsorsService;
