import React, { ReactNode, useEffect, useState } from "react";

import Title from "components/Title";
import MultipleColumnsLayout from "layouts/MultipleColumnsLayout";
import Meetings from "../Meetings";
import Rankings from "../Rankings";
import { INTERCLUBRESULTS_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  title?: string;
  currentClubId?: number;
}

const InterclubResults: React.FC<IProps> = (props: IProps) => {
  const { title = INTERCLUBRESULTS_DEFAULT_PROPS.title, currentClubId } = props;
  const [meetingsIsEmpty, setMeetingsIsEmpty] = useState(false)
  const [rankingsIsEmpty, setRankingsIsEmpty] = useState(false)
  const [componentsToDisplay, setComponentsToDisplay] = useState<ReactNode[]>([<Meetings setMeetingsIsEmpty={setMeetingsIsEmpty} />, <Rankings currentClubId={currentClubId} setRankingsIsEmpty={setRankingsIsEmpty} />])

  useEffect(() => {
    if (meetingsIsEmpty) {
      setComponentsToDisplay([<Rankings setRankingsIsEmpty={setRankingsIsEmpty} currentClubId={currentClubId} />])
    } else if (rankingsIsEmpty) {
      setComponentsToDisplay([<Meetings setMeetingsIsEmpty={setMeetingsIsEmpty} />])
    }
  }, [meetingsIsEmpty, rankingsIsEmpty]);

  return (
    <div className="interclub-results-container">
      <Title
        className="interclub-results-title"
        content={title}
        safetySpacing={false}
      />

      <MultipleColumnsLayout
        alignItems="flex-start"
        children={componentsToDisplay}
      />
    </div>
  );
};

export default InterclubResults;
