import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  title: string;
  subTitle?: string;
  showTimesIcon: boolean;
  onClose?: () => void;
}

const Heading: React.FC<IProps> = (props: IProps) => {
  const { title, subTitle, showTimesIcon, onClose } = props;

  return (
    <div className="modal__heading">
      <h3 className="modal__heading--title">{title}</h3>
      {subTitle && <div className="modal__heading--sub-title">{subTitle}</div>}

      {showTimesIcon && (
        <div className="modal__heading--times-icon" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes as any} />
        </div>
      )}
    </div>
  );
};

export default Heading;
