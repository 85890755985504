import { IPageComponentProperties } from "index";

export const buildNestedProps = (props: any): any => {
  const output = {};

  Object.keys(props).forEach((key) => {
    setDeepValue(output, key, props[key]);
  });

  return output;
};

const setDeepValue = (obj: any, path: string, value: any): void => {
  const keys = path.split(".");
  let tempObject = obj;

  while (keys.length - 1) {
    const aKey = keys.shift();
    if (!aKey) {
      break;
    }

    if (!(aKey in tempObject)) {
      tempObject[aKey] = {};
    }
    tempObject = tempObject[aKey];
  }
  tempObject[keys[0]] = value;
};

export const getNestedProps = (obj: any, path: string): any => {
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");
  const keys = path.split(".");
  let tempObject = obj;

  while (keys.length) {
    const aKey = keys.shift();
    if (!aKey) {
      break;
    }

    if (!(aKey in tempObject)) {
      return;
    }

    tempObject = tempObject[aKey];
  }
  return tempObject;
};

export const insertAtIndex = (
  array: IPageComponentProperties[],
  index: number,
  element: IPageComponentProperties
) => {
  if (index < 0) {
    index = 0;
  }

  if (!array.length) {
    array.push(element);
  } else if (array.length - 1 < index) {
    array.push(element);
  } else {
    array.splice(index, 0, element);
  }
};

export const cancellablePromise = <T>(
  promise: Promise<T>
): {
  promise: Promise<T>;
  cancel: () => void;
} => {
  let cancelled = false;

  const newPromise = new Promise<T>((resolve, reject) => {
    promise
      .then((response) => {
        if (!cancelled) {
          resolve(response);
        }
      })
      .catch((error) => {
        if (!cancelled) {
          reject(error);
        }
      });
  });

  return {
    promise: newPromise,
    cancel: () => {
      cancelled = true;
    },
  };
};

export const debugComponents = (components?: IPageComponentProperties[]) => {
  if (!components) {
    return;
  }

  let ids: { parentId: string; id: string; level: number }[] = [];
  let output = "";

  components?.forEach((component) => {
    let level = 0;

    if (component.parentId) {
      const exists = ids.find((p) => p.id === component.parentId);
      if (exists !== undefined) {
        level = exists.level + 1;
        ids.push({ parentId: component.parentId, id: component.id, level });
      } else {
        ids.push({ parentId: component.parentId, id: component.id, level: 1 });
      }
    } else {
      ids.push({ parentId: "", id: "top", level: 0 });
    }

    let tabs = "";
    for (let i = 0; i < level; i++) {
      tabs += "\t";
    }

    output += `${tabs}- ${component.id} (parentId: ${component.parentId})\n`;
  });

};
