import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { IWebsiteConfig } from "service/configuration";
import Link from "../Link";

interface IProps {
  onMenuItemClick: () => void;
  websiteConfiguration: IWebsiteConfig;
}

const ACTUALITY_ITEM: { pageId: string; label: string; url: string } = {
  // pageId: Not required for added items, but required for renderer.
  pageId: "",
  label: "Actualités",
  url: "/actualites",
};

const GENERIC_PAGES = {
  news: ACTUALITY_ITEM,
};

const SecondPart: React.FC<IProps> = (props: IProps) => {
  const { websiteConfiguration, onMenuItemClick } = props;
  const items = websiteConfiguration.configuration.menu.items;
  const genericPages = websiteConfiguration.configuration.menu.genericPages;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const potentialHomeItem = items.find((item) => item.url === "/");

    if (!potentialHomeItem) {
      return;
    }
    let indexAdd = 1;
    const index = items.indexOf(potentialHomeItem);
    if (genericPages) {
      Object.keys(genericPages).forEach((page) => {
        if (genericPages[page]) {
          items.splice(index + indexAdd, 0, GENERIC_PAGES[page]);
          indexAdd++;
        }
      });
    }
    setLoading(false);
  }, []);

  return !loading ? (
    <div className="burgerMenu">
      <FontAwesomeIcon
        icon={faBars as any}
        className="burgerIcon"
        size={"3x"}
      />
      <ul className="links">
        {items.map((item, index) => (
          <Link key={index} item={item} onMenuItemClick={onMenuItemClick} />
        ))}
      </ul>
    </div>
  ) : (
    <></>
  );
};

export default SecondPart;
