import { IInstanceFull } from "core/interfaces/api.interfaces";
import { useInstanceId } from "hooks";
import React, { useEffect, useState } from "react";
import InstanceService from "service/instance";

import FOOTER_LOGO from "../../../../assets/img/footer-logo2.png";

const instanceService = new InstanceService();

const Logo: React.FC = () => {
  const [instance, setInstance] = useState<IInstanceFull>();
  const instanceId = useInstanceId();

  useEffect(() => {
    if (!instanceId) {
      return;
    }

    const { promise, cancel } = instanceService.full(instanceId);
    promise.then(setInstance);
    return () => cancel();
    // eslint-disable-next-line
  }, [instanceId]);

  return (
    <div style={{ flexDirection: "row", display: "flex", width: "100%" }}>
      <img src={FOOTER_LOGO} alt="ffbad logo" height={200} />
      <img src={instance?.logo} alt="instance logo" width={200} height={200} />
    </div>
  );
};

export default Logo;
