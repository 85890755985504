import PageBuilder, { ComponentTypes } from "../pageBuilder";
import { IPageComponentProperties } from "index";
import { insertAtIndex } from "utils/object/object.utils";
import { findChildOfComponent, findComponentById } from "../utils/editor.utils";
import { randomString } from "utils/string/string.utils";

export interface IEditableModeArgs {
  onClickCallback: (componentId: string, isLight: boolean, componentType: ComponentTypes) => void;
}

class EditorAppender {
  private editor: PageBuilder;
  private editableModeArgs: IEditableModeArgs;

  constructor(editor: PageBuilder) {
    this.editor = editor;
  }

  get components(): IPageComponentProperties[] | undefined {
    return this.editor.properties.components;
  }

  set components(values: IPageComponentProperties[] | undefined) {
    this.editor.properties.components = values;
  }

  public append = (newComponents: IPageComponentProperties[]) => {
    newComponents.forEach((component) => this.components?.push(component));
  };

  public appendAfter = (
    componentId: string,
    newComponents: IPageComponentProperties[]
  ) => {
    const aComponent = findComponentById(componentId, this.components);

    if (!aComponent || !this.components) {
      console.error("[EDITOR|APPENDAFTER] Unable to find component", {
        componentId,
      });
      return;
    }

    let elementIndex = this.components.indexOf(aComponent);
    newComponents.forEach((component) => {
      insertAtIndex(this.components || [], ++elementIndex, component);
    });
  };

  public setEditableMode = (args: IEditableModeArgs) => {
    this.editableModeArgs = args;
    const newComponents: IPageComponentProperties[] = [
      ...(this.components || []),
    ];

    this.createAllElements(newComponents);
    this.components = newComponents;
  };

  createAllElements = (components: IPageComponentProperties[]) => {
    [...components].forEach((aComponent) => {
      // if (!this.canWrapLayout(aComponent)) {
      //   return;
      // }

      const startIndex = components.indexOf(aComponent);
      const beforeIndex = startIndex - 1;
      const afterIndex = startIndex + 1;
      const light = aComponent.parentId !== "top";

      const parentId = aComponent.parentId;
      const parent = components.find((p) => p.id === aComponent.parentId);
      let firstParent;
      let grandParent;

      if (parent) {
        grandParent = components.find((p) => p.id === parent.parentId && p.props !== undefined);
        if (grandParent) {
          let currentParent = grandParent as IPageComponentProperties | undefined;
          while (currentParent?.parentId !== 'top') {
            currentParent = components.find((p) => p.id === currentParent?.parentId && p.props !== undefined);
          }
          firstParent = currentParent;
        } else {
          firstParent = parent;
        }
      } else {
        firstParent = aComponent;
      }

      switch (aComponent.type) {
        case ComponentTypes.TopLevel:
        case ComponentTypes.Header:
        case ComponentTypes.Footer:
        case ComponentTypes.Heading:
          // Nothing. Required to be separated of the "default" case.
          break;
        case ComponentTypes.MultipleColumnsLayout:
        case ComponentTypes.MultipleColumnsLayoutOneBig:
        case ComponentTypes.FlexContainer:
        case ComponentTypes.FlexContent:
          const aParent = components.find((p) => p.id === aComponent.parentId);
          if (aParent?.props?.flexDirection === "row" || aParent?.type === ComponentTypes.CustomFooter) {
            break;
          }

          insertAtIndex(components, afterIndex, {
            ...this.buildAddNewSectionComponent(aComponent.type),
            parentId,
          });

          // If previous element is an AddElement, don't add another one.
          const elementBeforeMe = [...components][beforeIndex];
          if (elementBeforeMe?.type !== ComponentTypes.AddElement) {
            insertAtIndex(components, startIndex, {
              ...this.buildAddNewSectionComponent(aComponent.type),
              parentId,
            });
          }

          break;
        case ComponentTypes.CustomFooter:
          if (components.filter(c => c.parentId === aComponent.id).length > 0) {
            break;
          }

          insertAtIndex(components, afterIndex, {
            ...this.buildAddNewSectionComponent(aComponent.type),
            parentId: aComponent.id,
          });

          break;
        default:
          // All other components.

          // If parent can be wrapped.

          if ((parent && !this.canWrapLayout(parent)) || firstParent?.type === ComponentTypes.CustomFooter) {
            break;
          }

          insertAtIndex(components, afterIndex, {
            ...this.buildAddNewElementComponent(light, aComponent.type),
            parentId,
          });
          const elementBeforeMeOther = [...components][beforeIndex];

          if ((elementBeforeMeOther?.type === ComponentTypes.AddElement && !elementBeforeMeOther.props.light) || elementBeforeMeOther?.type !== ComponentTypes.AddElement) {
            insertAtIndex(components, startIndex, {
              ...this.buildAddNewElementComponent(light, aComponent.type),
              parentId,
            });
          }
          break;
      }
    });
  };

  private buildAddNewSectionComponent = (componentType: ComponentTypes): IPageComponentProperties => {
    const id = randomString();
    return {
      id,
      type: ComponentTypes.AddElement,
      props: {
        label: "Ajouter une section",
        onClick: () => this.editableModeArgs.onClickCallback(id, false, componentType),
      },
    };
  };

  private buildAddNewElementComponent = (
    light = false,
    componentType: ComponentTypes,
  ): IPageComponentProperties => {
    const id = randomString();
    return {
      id,
      type: ComponentTypes.AddElement,
      props: {
        label: "Ajouter un élément",
        light,
        showOnHover: false, // Put "light" if needed.
        onClick: () => this.editableModeArgs.onClickCallback(id, light, componentType),
      },
    };
  };

  private canWrapLayout = (component: IPageComponentProperties): boolean => {
    const encapsulableTypes = [
      ComponentTypes.MultipleColumnsLayout,
      ComponentTypes.MultipleColumnsLayoutOneBig,
    ];

    return (
      encapsulableTypes.includes(component.type) &&
      component.props?.flexDirection &&
      component.props?.flexDirection !== "row"
    );
  };

  private findIndexToInsertAfterAllChildren = (
    startIndex: number,
    parent: IPageComponentProperties,
    list: IPageComponentProperties[]
  ) => {
    const children = findChildOfComponent(parent.id, list);
    startIndex += children.length;

    for (const child of children) {
      startIndex = this.findIndexToInsertAfterAllChildren(
        startIndex,
        child,
        list
      );
    }

    return startIndex;
  };
}

export default EditorAppender;
