import React, { ReactNode } from "react";

import MultipleColumnsLayout from "layouts/MultipleColumnsLayout";
import FlexImage from "components/FlexImage";
import Versus from "../Versus";
import {
  IMeetingData,
  IMeetingTeam,
} from "fullComponents/Meetings/meetings.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  data: IMeetingData;
}

const BottomDisplay: React.FC<IProps> = (props: IProps) => {
  const {
    data: { Equipe1, Equipe2 },
  } = props;

  return (
    <MultipleColumnsLayout
      children={[
        <div></div>,
        <Versus
          key="versus"
          firstScore={Number(Equipe1.MatchsGagnes)}
          secondScore={Number(Equipe2.MatchsGagnes)}
        />,
        <div></div>,
      ]}
    />
  );
};

export default BottomDisplay;

const getPicture = (team: IMeetingTeam): ReactNode => {
  const hasLogo = team.Clubs.length === 1 && team.Clubs[0].Logo;

  if (!hasLogo) {
    return (
      <div className="u-center">
        <FontAwesomeIcon icon={faUsers as any} size="4x" />
      </div>
    );
  }

  return (
    <FlexImage
      src={team.Clubs[0].Logo}
      style={{ backgroundPosition: "center" }}
      size="contain"
    />
  );
};
