import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";

import SponsorsService from "service/Sponsors/sponsors.service";
import { useWebsiteId } from "hooks";
import Item from "./components/Item";
import Title from "components/Title";

import "react-multi-carousel/lib/styles.css";

interface IProps {
  title?: string;
  autoPlay?: boolean;
  // This was a typo error, but needed to avoid to break old websites.
  autoplay?: boolean;
  interval?: number;
}

const service = new SponsorsService();

const Sponsors: React.FC<IProps> = (props: IProps) => {
  const { title, interval, autoPlay = false, autoplay = false } = props;
  const realAutoplay = autoPlay || autoplay;
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const websiteId = useWebsiteId();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const Fix: any = Carousel;

  const CarouselFixed = (): any => (
    <Fix
      responsive={responsive}
      showDots={false}
      infinite={true}
      keyBoardControl={true}
      customTransition="transform 500ms ease-in-out"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      autoPlay={realAutoplay}
      autoPlaySpeed={interval && realAutoplay ? interval * 1000 : 3000}
      arrows={true}
      itemClass="carousel-item-sponsors"
      centerMode={true}
    >
      {items.map((item, index) => (
        <Item key={item.title || index} item={item} />
      ))}
    </Fix>
  );

  useEffect(() => {
    const { promise, cancel } = service.sponsors(websiteId);
    promise
      .then((response: any[]) => {
        setItems(response);
        setIsLoading(false);
      })
      .catch(() => {
        // nothing.
      });

    return () => cancel();
  }, [websiteId]);

  return (
    <>
      <Title content={title ? title : "Nos partenaires"} />
      <div className="carousel-container-sponsors">
        {!isLoading && <CarouselFixed />}
      </div>
    </>
  );
};

export default Sponsors;
