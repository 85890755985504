import React, { useEffect, useState } from "react";

import { IInstanceFull } from "core/interfaces/api.interfaces";
import useInstanceId from "hooks/useInstanceId";
import InstanceService from "service/instance";
import Loading from "components/Loading";
import ContactHeader from "./components/ContactHeader";
import ContactInformations from "./components/ContactInformations";
import { CONTACT_DEFAULT_PROPS } from "./editor.fields";
import Heading from "components/Heading";

interface IProps {
  title?: string;
  postalAddressLabel?: string;
  emailLabel?: string;
  phoneLabel?: string;
  hideHeader?: boolean;
}

const service = new InstanceService();

const Contact: React.FC<IProps> = (props: IProps) => {
  const [instance, setInstance] = useState<IInstanceFull>();
  const [loading, setLoading] = useState(true);
  const instanceId = useInstanceId();
  const {
    title = CONTACT_DEFAULT_PROPS.title,
    postalAddressLabel = CONTACT_DEFAULT_PROPS.postalAddressLabel,
    emailLabel = CONTACT_DEFAULT_PROPS.emailLabel,
    phoneLabel = CONTACT_DEFAULT_PROPS.phoneLabel,
    hideHeader = CONTACT_DEFAULT_PROPS.hideHeader,
  } = props;

  useEffect(() => {
    const { promise, cancel } = service.full(instanceId);
    promise
      .then(setInstance)
      .catch()
      .finally(() => setLoading(false));
    return () => cancel();
  }, [instanceId]);

  return (
    <section className="contact-container">
      {!hideHeader ? <ContactHeader title={title} /> : <Heading content={title} />}

      {loading && <Loading />}

      {!loading && instance && (
        <ContactInformations
          instance={instance}
          postalAddressLabel={postalAddressLabel}
          emailLabel={emailLabel}
          phoneLabel={phoneLabel}
        />
      )}
    </section>
  );
};

export default Contact;
