import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faFax } from "@fortawesome/free-solid-svg-icons";

import { IInstanceFull } from "core/interfaces/api.interfaces";

interface IProps {
  instance: IInstanceFull;
  postalAddressLabel: string;
  emailLabel: string;
  phoneLabel: string;
}

const ContactInformations: React.FC<IProps> = (props: IProps) => {
  const { instance, postalAddressLabel, emailLabel, phoneLabel } = props;

  return (
    <div className="contact-informations-container">
      <div className="contact-informations-adddress">
        <strong>{postalAddressLabel}</strong>
        <p>
          {instance.name}
          <br />
          {instance.address.address}
          <br />
          {instance.address.postalCode}, {instance.address.city}
          <br />
        </p>
      </div>
      {instance.email && (
        <div className="contact-informations-adddress">
          <strong>{emailLabel}</strong>
          <p>
            <a href={`mailto:${instance.email}`} title={instance.email}>
              {instance.email}
            </a>
          </p>
        </div>
      )}
      <div className="contact-informations-adddress">
        <strong>{phoneLabel}</strong>
        {instance.mobile && (
          <p>
            <FontAwesomeIcon icon={faMobileAlt as any} />
            &nbsp;
            <a href={`tel:${instance.mobile}`} title={instance.mobile}>
              {instance.mobile}
            </a>
          </p>
        )}

        {instance.fax && (
          <p>
            <FontAwesomeIcon icon={faFax as any} />
            &nbsp;
            {instance.fax}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactInformations;
