import React, { useState, useEffect } from "react";

import { IInstanceFull } from "core/interfaces/api.interfaces";
import InstanceService from "service/instance";
import { IWebsiteConfig } from "service/configuration";
import { useConfigurationService, useInstanceId } from "hooks";
import FirstPart from "./components/FirstPart";
import SecondPart from "./components/SecondPart";

const instanceService = new InstanceService();

interface IProps {
  onMenuItemClick: () => void;
}

const Header: React.FC<IProps> = (props: IProps) => {
  const configurationService = useConfigurationService();
  const [instance, setInstance] = useState<IInstanceFull>();
  const [websiteConfiguration, setWebsiteConfiguration] =
    useState<IWebsiteConfig>();
  const instanceId = useInstanceId();
  const { onMenuItemClick } = props;

  useEffect(() => {
    if (!instanceId) {
      return;
    }

    const { promise, cancel } = instanceService.full(instanceId);
    promise.then(setInstance);
    return () => cancel();
    // eslint-disable-next-line
  }, [instanceId]);

  useEffect(() => {
    if (!instanceId) {
      return;
    }

    const { promise, cancel } = configurationService.getByOwnerId(instanceId);
    promise.then(setWebsiteConfiguration).catch(() => {
      // Nothing
    });

    return () => cancel();
    // eslint-disable-next-line
  }, [instanceId]);

  if (!websiteConfiguration) {
    return null;
  }

  return (
    <header className="header-main">
      {instance && (
        <div
          className="header-main--logo"
          style={{
            backgroundImage: `url("${instance.logo}")`,
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = window.location.origin;
          }}
        />
      )}

      <div className="content">
        <div className="header-main__first-part">
          <FirstPart websiteConfiguration={websiteConfiguration} />
        </div>
        <div className="header-main__second-part">
          <SecondPart
            websiteConfiguration={websiteConfiguration}
            onMenuItemClick={onMenuItemClick}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
