import React from "react";
import { INewsProperty } from "fullComponents/News";
import Button from "components/Button";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const DATE_FORMAT = "dddd Do MMMM YYYY";
interface IProps {
  aNews?: INewsProperty;
  onBack: () => void;
}

const Content: React.FC<IProps> = (props: IProps) => {
  const { aNews, onBack } = props;

  if (!aNews) {
    return null;
  }

  return (
    <div className="display-news__content">
      <div className="header">
        <Button type="primary" safetySpacing={false} onClick={onBack}>
          <FontAwesomeIcon icon={faChevronLeft as any} />
          &nbsp; Retour
        </Button>
        <h1>{aNews.title}</h1>
      </div>
      <span className="display-news__content--date">
        Postée le {moment(aNews.updatedAt).format(DATE_FORMAT)}
      </span>
      <div
        className="text"
        dangerouslySetInnerHTML={{ __html: aNews.shortContent }}
      />
    </div>
  );
};

export default Content;
