import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "displayPicture",
    label: "Afficher les photos",
    type: "checkbox",
    required: false,
  },
];

export const BUREAU_DEFAULT_PROPS = {
  displayPicture: true,
};

export default fields;
