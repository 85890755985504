import React, { useState, useEffect } from "react";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

import NewsService from "service/news/news.service";
import { useWebsiteId } from "hooks";
import Item from "./components/Item";
import { INewsProperty } from "fullComponents/News";
import Empty from "components/Empty";

interface IProps {
  autoPlay?: boolean;
  interval?: number;
  onClick: (aNewsId: string) => void;
}

const service = new NewsService();

const Carousel: React.FC<IProps> = (props: IProps) => {
  const { autoPlay = false, onClick, interval } = props;
  const [items, setItems] = useState<INewsProperty[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const websiteId = useWebsiteId();

  useEffect(() => {
    const { promise, cancel } = service.carrousel(websiteId);
    promise
      .then((response: INewsProperty[]) => {
        setItems(response);
        setIsLoading(false);
      })
      .catch(() => { });

    return () => cancel();
  }, [websiteId]);

  return (
    <div className="carousel-container">
      {items.length !== 0 ? (
        <ResponsiveCarousel
          showArrows
          showThumbs={false}
          autoPlay={autoPlay}
          showStatus={false}
          stopOnHover
          infiniteLoop
          useKeyboardArrows
          emulateTouch
          interval={interval && autoPlay ? interval * 1000 : 3000}
          showIndicators={items.length > 1}
        >
          {items.map((item) => (
            <Item key={item.title} item={item} onClick={onClick} />
          ))}
        </ResponsiveCarousel>
      )
        :
        <Empty>
          <p>Vous avez actuellement 0 actualité mis en avant au sein du carousel.</p>
          <p>Vous pouvez ajouter à tout moment une actualité depuis l’onglet “Actualités” présent dans le menu de l’éditeur.</p>
        </Empty>
      }
    </div>
  );
};

export default Carousel;
