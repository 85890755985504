import React from "react";

import SafetySpacing from "layouts/SafetySpacing";
import Empty from "components/Empty";

import YOUTUBE_ICON from "../../assets/img/youtube.png";

interface IProps {
  url?: string;
}

const YouTubePlayer: React.FC<IProps> = (props: IProps) => {
  const { url } = props;

  const cleanURL = (url: string) => {
    if (url.includes('watch?v=')) {
      return url.replace('watch?v=', 'embed/');
    } else {
      return url;
    }
  }

  if (!url) {
    return (
      <SafetySpacing>
        <Empty>
          <img src={YOUTUBE_ICON} alt="youtube" width={150} />
          <br />
          Veuillez saisir le lien d'une vidéo YouTube
        </Empty>
      </SafetySpacing>
    );
  }

  return (
    <SafetySpacing>
      <div className="youtube-player-container">
        <iframe
          title={url}
          src={cleanURL(url)}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </SafetySpacing>
  );
};

export default YouTubePlayer;
