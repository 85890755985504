import React, { ReactNode } from "react";

import { buildClassName } from "utils/dom/dom.utils";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
  flexDirection?: "row" | "column";
  alignItems?: "flex-start" | "flex-end" | "center";
}

const FlexContainer: React.FC<IProps> = (props: IProps) => {
  const {
    className,
    style,
    children,
    flexDirection = "row",
    alignItems,
  } = props;
  const mainClassName = buildClassName("flex-container", [
    [className !== undefined, className],
    [`flex-direction-${flexDirection}`],
  ]);

  return (
    <div
      className={mainClassName}
      style={{ ...style, alignItems }}
      data-direction={flexDirection}
    >
      {children}
    </div>
  );
};

export default FlexContainer;
