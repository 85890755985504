import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "title",
    label: "Titre",
    type: "text",
    required: false,
  },
  {
    id: "description",
    label: "Description",
    type: "textarea",
    required: false,
  },
  {
    id: "bottomText",
    label: "Texte du bas",
    type: "text",
    required: false,
  },
];

export const SUBSCRIBENOW_DEFAULT_PROPS = {
  title: "Abonne-toi dès maintenant",
  description:
    "En t’abonnant à notre newsletter tu pourras rester au courant de l’actualité et du calendrier du club.",
  bottomText: "",
};

export default fields;
