import React from "react";

import { useBreakpoints } from "hooks";
import { cutString } from "utils/string/string.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  categories: { title: string; description: string }[];
}

const MAX_TITLE_LENGTH = 64;

const DisplayLegend: React.FC<IProps> = (props: IProps) => {
  const { XS } = useBreakpoints();

  if (XS) return null;

  const { categories = [] } = props;

  return (
    <div className="display-legend">
      {categories.map((category) => (
        <div
          key={category.title}
          className="display-legend--item"
          title={category.title}
        >
          {cutString(category.title, MAX_TITLE_LENGTH)}{" "}
          {category.description && (
            <FontAwesomeIcon
              icon={faInfoCircle as any}
              style={{ marginLeft: 5 }}
              title={removeHtmlTags(category.description)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default DisplayLegend;

function removeHtmlTags(inputString: string): string {
  // Regular expression to match HTML tags
  const regex = /<\/?[^>]+(>|$)/g;

  // Replace HTML tags with an empty string
  return inputString.replace(regex, "");
}
