import React from "react";

interface IProps {
  firstScore: number;
  secondScore: number;
}

const Versus: React.FC<IProps> = (props: IProps) => {
  const { firstScore, secondScore } = props;
  return (
    <div className="meetings-container__versus">
      <span>VS</span>
      <span>
        {firstScore} - {secondScore}
      </span>
    </div>
  );
};

export default Versus;
