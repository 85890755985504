import React, { ReactNode, CSSProperties } from "react";
import { buildClassName } from "utils/dom/dom.utils";

interface IProps {
  shape: "paragraph" | "title" | "picture";
  repeat?: number;
  style?: CSSProperties;
}

const Skeleton: React.FC<IProps> = (props: IProps) => {
  const { shape, repeat = 1, style } = props;

  const output: ReactNode[] = [];
  const className = buildClassName("skeleton", [[`skeleton--${shape}`]]);

  for (let i = 0; i < repeat; i++) {
    output.push(<div key={i} className={className} style={style} />);
  }

  return <>{output}</>;
};

export default Skeleton;
