export const cutString = (
  content: string,
  length: number,
  chars = "..."
): string => {
  if (content.length <= length) {
    return content;
  }

  return content.substr(0, length).trim() + chars;
};

export const randomString = (length = 10): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const stripHtml = (html: string): string => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const getCDN = (): string => {
  return window.location.host.includes(".mobi") ||
    window.location.host.includes("localhost")
    ? "https://cdn.ffbad.mobi/"
    : "https://cdn.ffbad.club/";
};
