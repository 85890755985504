import { IEditorField } from "utils/editor/components.fields";
import getDefaultEditableFields from "core/types/editorDefaultFields";

const fields: IEditorField[] = [
  {
    id: "url",
    label: "Lien",
    type: "text",
    required: true
  },
  ...getDefaultEditableFields(["width", "height"])
];

export default fields;
