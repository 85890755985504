import React from "react";
import { faCrown } from "@fortawesome/free-solid-svg-icons";

import { PlayerRoles } from "core/types/playerRoles";
import { IInterclubTeam } from "service/service.interfaces";
import User from "../User";

interface IProps {
  team: IInterclubTeam;
}

const WANTED_ROLES = [
  PlayerRoles.TITULAR,
  PlayerRoles.SUBSTITUTE,
  PlayerRoles.NONE,
];

const TeamMembers: React.FC<IProps> = (props: IProps) => {
  const { team } = props;
  const captain = team.Joueurs.find((p) => p.ID_Role === PlayerRoles.CAPTAIN);

  return (
    <>
      <div className="org-chart-chief">
        {captain && <User user={captain} icon={faCrown as any} />}
      </div>

      {WANTED_ROLES.map((role) => {
        const players = team.Joueurs.filter(
          (joueur) => joueur.ID_Role === role
        );

        if (!players.length) {
          return null;
        }

        return (
          <div key={role}>
            <div className="org-chart-separator" />
            <div className="org-chart-users-container">
              {players.map((user, index: number) => (
                <User key={index} user={user} />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TeamMembers;
