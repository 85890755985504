import React, { useState, useEffect } from "react";

import InstanceService from "service/instance";
import useInstanceId from "hooks/useInstanceId";
import CalendarItem from "./components/CalendarItem";
import Skeleton from "components/Skeleton";

export interface IFloatingCalendarItem {
  date: Date;
  type: string;
  description: string;
  onClick: () => void;
}

const service = new InstanceService();

const FloatingCalendar: React.FC = () => {
  const instanceId = useInstanceId();
  const [isLoading, setIsLoading] = useState(true);
  const [calendarItems, setCalendarItems] = useState<IFloatingCalendarItem[]>(
    []
  );

  useEffect(() => {
    const { promise, cancel } = service.nextEvents(instanceId);
    promise.then((response: any) => {
      setCalendarItems(response);
      setIsLoading(false);
    });

    return () => cancel();
  }, [instanceId]);

  return (
    <div className="floating-calendar-container">
      <div className="floating-calendar-title">Calendriers</div>

      {isLoading && <Skeleton shape="paragraph" repeat={3} />}

      {!isLoading &&
        calendarItems.map((item) => (
          <CalendarItem key={item.description} item={item} />
        ))}
    </div>
  );
};

export default FloatingCalendar;
