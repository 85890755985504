import React, { useEffect, useState } from "react";

import InstanceService from "service/instance";
import { IPrice } from "service/service.interfaces";
import { useBreakpoints, useInstanceId } from "hooks";
import DisplayPrice from "./components/DisplayPrice";
import Heading from "./components/Heading";
import ErrorMessage from "./components/ErrorMessage";
import EmptyMessage from "./components/EmptyMessage";
import Loading from "components/Loading";
import DisplayLegend from "./components/DisplayLegend";
import { PRICES_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  title: string;
  priceByRow: number;
}

const service = new InstanceService();

const Prices: React.FC<IProps> = (props: IProps) => {
  const {
    title = PRICES_DEFAULT_PROPS.title,
    priceByRow = PRICES_DEFAULT_PROPS.priceByRow,
  } = props;
  const [pricesArray, setPricesArray] = useState<IPrice[][]>([]);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const instanceId = useInstanceId();
  const [categories, setCategories] = useState<
    { title: string; description: string }[]
  >([]);
  const { XS: isMobile } = useBreakpoints();
  const priceCountPerRows = isMobile ? 1 : priceByRow;

  useEffect(() => {
    const { promise, cancel } = service.prices(instanceId);
    promise
      .then((response) => {
        const array: IPrice[][] = [];
        let indexToCut = 0;

        setCategories(extractCategories(response));
        const numberOfArray = Math.ceil(response.length / priceCountPerRows);
        for (let index = 0; index < numberOfArray; index++) {
          array.push(
            response.slice(indexToCut, indexToCut + priceCountPerRows)
          );
          indexToCut += priceCountPerRows;
        }
        setPricesArray(array);
      })
      .catch(() => setHasError(true))
      .finally(() => setLoading(false));
    return () => cancel();
  }, [instanceId, priceCountPerRows]);

  return (
    <section className="prices">
      <Heading title={title} />

      {pricesArray.map((prices, index) => (
        <div key={index} className="prices__body">
          {categories.length > 0 && <DisplayLegend categories={categories} />}

          {prices.map((price) => (
            <DisplayPrice
              key={price.priceId}
              price={price}
              categories={categories}
            />
          ))}
        </div>
      ))}

      {loading && <Loading />}

      {hasError && <ErrorMessage />}

      {!loading && !hasError && pricesArray.length === 0 && <EmptyMessage />}
    </section>
  );
};

export default Prices;

const extractCategories = (prices: IPrice[]) => {
  const allDiscountLabels = extractLabels(prices, "discounts");
  const allOptionLabels = extractLabels(prices, "options");

  const uniqueDiscountArray = allDiscountLabels.filter(
    (value, index, self) =>
      index === self.findIndex((obj) => obj.title === value.title)
  );

  const uniqueOptionArray = allOptionLabels.filter(
    (value, index, self) =>
      index === self.findIndex((obj) => obj.title === value.title)
  );

  return [...uniqueDiscountArray, ...uniqueOptionArray];
};

const extractLabels = (
  array: {
    discounts: { name: string; description: string }[];
    options: { name: string; description: string }[];
  }[] = [],
  key: "discounts" | "options"
) => {
  return array.reduce(
    (prev, curr) => [
      ...prev,
      ...curr[key].map((p) => ({
        title: p.name,
        description: p.description,
      })),
    ],
    []
  );
};
