import React, { ReactNode } from "react";

import Heading from "./components/Heading";
import Body from "./components/Body";
import Footer from "./components/Footer";

interface IProps {
  visible: boolean;
  title: string;
  subTitle?: string;
  children: ReactNode;
  labels?: { successButtonLabel?: string; cancelButtonLabel?: string };
  onSuccess?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  canClose?: boolean;
}

const Modal: React.FC<IProps> = (props: IProps) => {
  const {
    visible,
    title,
    subTitle,
    children,
    labels = {
      successButtonLabel: "OK !",
      cancelButtonLabel: "Annuler",
    },
    onSuccess,
    onClose,
    canClose = true,
  } = props;

  const handleOnClick = (handler?: () => void) => {
    return (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      interceptEvents(e);
      if (handler) {
        handler();
      }
    };
  };

  return (
    <div className="modal" data-visible={visible}>
      <div
        className="modal__backdrop"
        onClick={!canClose ? () => {} : handleOnClick(onClose)}
      >
        <div className="modal__box" onClick={interceptEvents}>
          <Heading
            title={title}
            subTitle={subTitle}
            showTimesIcon={canClose}
            onClose={!canClose ? () => {} : handleOnClick(onClose)}
          />
          <Body>{children}</Body>
          <Footer labels={labels} onSuccess={handleOnClick(onSuccess)} />
        </div>
      </div>
    </div>
  );
};

export default Modal;

const interceptEvents = (
  event?: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  if (!event) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
};
