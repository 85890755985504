import Button from "components/Button";
import Link from "components/Link";
import GoogleDrive from "components/GoogleDrive";
import Map from "components/Map";
import Separator from "components/Separator";
import Title from "components/Title";
import YouTubePlayer from "components/YouTubePlayer";
import FlexImage from "components/FlexImage";
import TextEditor from "components/TextEditor";
import Heading from "components/Heading";

// Layouts.
import FlexContainer from "layouts/FlexContainer";
import FlexContent from "layouts/FlexContent";
import MultipleColumnsLayout from "layouts/MultipleColumnsLayout";
import MultipleColumnsLayoutOneBig from "layouts/MultipleColumnsLayoutOneBig";
import AddElement from "layouts/AddElement";
import CustomFooter from "layouts/CustomFooter";

// Full components.
import Carousel from "fullComponents/Carousel";
import CarouselCalendar from "fullComponents/CarouselCalendar";
import FloatingCalendar from "fullComponents/FloatingCalendar";
import InterclubResults from "fullComponents/InterclubResults";
import Meetings from "fullComponents/Meetings";
import News from "fullComponents/News";
import QuickAccess from "fullComponents/QuickAccess";
import Rankings from "fullComponents/Rankings";
import SubscribeNow from "fullComponents/SubscribeNow";
import QuickDocumentAccess from "fullComponents/QuickDocumentAccess";
import Bureau from "fullComponents/Bureau";
import Contact from "fullComponents/Contact";
import InterclubTeams from "fullComponents/InterclubTeams";
import CalendarActivity from "fullComponents/CalendarActivity";
import Footer from "fullComponents/Footer";
import Header from "fullComponents/Header";
import Albums from "fullComponents/Albums";
import Prices from "fullComponents/Prices";
import DisplayNews from "fullComponents/DisplayNews";
import DisplayAllNews from "fullComponents/DisplayAllNews";
import LeftMenu from "fullComponents/LeftMenu";
import SlotsEquipments from "fullComponents/SlotsEquipments"
import ConnectionForm from "fullComponents/ConnectionForm"
import Sponsors from "fullComponents/Sponsors"
import CustomFooterContact from "fullComponents/CustomFooter/CustomFooterContact"
import CustomFooterLogo from "fullComponents/CustomFooter/CustomFooterLogo"

export enum ComponentTypes {
  TopLevel = 1,

  // Components
  Button = 1000,
  FlexImage = 1001,
  Link = 1002,
  GoogleDrive = 1003,
  Map = 1004,
  Separator = 1005,
  Title = 1006,
  YouTubePlayer = 1007,
  TextEditor = 1008,
  Heading = 1009,

  // Layouts.
  FlexContainer = 2000,
  FlexContent = 2001,
  MultipleColumnsLayout = 2002,
  MultipleColumnsLayoutOneBig = 2003,
  AddElement = 2004,
  CustomFooter = 2005,

  // Full components.
  Carousel = 3000,
  CarouselCalendar = 3001,
  FloatingCalendar = 3002,
  InterclubResults = 3003,
  Meetings = 3004,
  News = 3005,
  QuickAccess = 3006,
  Rankings = 3007,
  SubscribeNow = 3008,
  QuickDocumentAccess = 3009,
  Bureau = 3010,
  Contact = 3011,
  InterclubTeams = 3012,
  CalendarActivity = 3013,
  Footer = 3014,
  Header = 3015,
  Albums = 3016,
  Prices = 3017,
  DisplayAllNews = 3018,
  DisplayNews = 3019,
  LeftMenu = 3020,
  SlotsEquipments = 3021,
  ConnectionForm = 3022,
  Sponsors = 3023,
  CustomFooterContact = 3024,
  CustomFooterLogo = 3025,

}

const COMPONENTS_MAPPING: { [x: number]: React.FC<object> } = {
  [ComponentTypes.Button]: Button,
  [ComponentTypes.Link]: Link,
  [ComponentTypes.GoogleDrive]: GoogleDrive,
  [ComponentTypes.Map]: Map,
  [ComponentTypes.Separator]: Separator,
  [ComponentTypes.Title]: Title,
  [ComponentTypes.Heading]: Heading,
  [ComponentTypes.YouTubePlayer]: YouTubePlayer,
  [ComponentTypes.FlexImage]: FlexImage,
  [ComponentTypes.TextEditor]: TextEditor,

  // Layouts.
  [ComponentTypes.FlexContainer]: FlexContainer,
  [ComponentTypes.FlexContent]: FlexContent,
  [ComponentTypes.MultipleColumnsLayout]: MultipleColumnsLayout,
  [ComponentTypes.MultipleColumnsLayoutOneBig]: MultipleColumnsLayoutOneBig,
  [ComponentTypes.AddElement]: AddElement,
  [ComponentTypes.CustomFooter]: CustomFooter,

  // Full components.
  [ComponentTypes.Carousel]: Carousel,
  [ComponentTypes.CarouselCalendar]: CarouselCalendar,
  [ComponentTypes.FloatingCalendar]: FloatingCalendar,
  [ComponentTypes.InterclubResults]: InterclubResults,
  [ComponentTypes.Meetings]: Meetings,
  [ComponentTypes.News]: News,
  [ComponentTypes.QuickAccess]: QuickAccess,
  [ComponentTypes.Rankings]: Rankings,
  [ComponentTypes.SubscribeNow]: SubscribeNow,
  [ComponentTypes.QuickDocumentAccess]: QuickDocumentAccess,
  [ComponentTypes.Bureau]: Bureau,
  [ComponentTypes.Contact]: Contact,
  [ComponentTypes.InterclubTeams]: InterclubTeams,
  [ComponentTypes.CalendarActivity]: CalendarActivity,
  [ComponentTypes.Footer]: Footer,
  [ComponentTypes.Header]: Header,
  [ComponentTypes.Albums]: Albums,
  [ComponentTypes.Prices]: Prices,
  [ComponentTypes.DisplayNews]: DisplayNews,
  [ComponentTypes.DisplayAllNews]: DisplayAllNews,
  [ComponentTypes.LeftMenu]: LeftMenu,
  [ComponentTypes.SlotsEquipments]: SlotsEquipments,
  [ComponentTypes.ConnectionForm]: ConnectionForm,
  [ComponentTypes.Sponsors]: Sponsors,
  [ComponentTypes.CustomFooterContact]: CustomFooterContact,
  [ComponentTypes.CustomFooterLogo]: CustomFooterLogo,
};

export default COMPONENTS_MAPPING;
