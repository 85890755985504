import React, { CSSProperties } from "react";
// import { Map as Leaflet, Marker, Popup, TileLayer } from "react-leaflet";

interface IProps {
  zoom?: number;
  center: IMapPosition;
  onMapClick?: (position: IMapPosition) => void;
  markers?: IMapMarker[];
  style?: CSSProperties;
}

export interface IMapPosition {
  lat: number;
  lng: number;
}

export interface IMapMarker {
  popup?: string;
  position: IMapPosition;
}

// const DEFAULT_ZOOM = 13;

const Map: React.FC<IProps> = (/*props: IProps */) => {
  // const {
  //   center,
  //   zoom = DEFAULT_ZOOM,
  //   onMapClick,
  //   markers = [],
  //   style
  // } = props;

  return (
    <div>Can't build with this component... will try to find a solution.</div>
  );
  // return (
  //   <Leaflet
  //     style={style}
  //     center={center}
  //     zoom={zoom}
  //     onClick={(e: any) => onMapClick && onMapClick(e.latlng)}
  //   >
  //     <TileLayer
  //       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //       attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //     />

  //     {markers.map((marker, index) => (
  //       <Marker key={index} position={marker.position}>
  //         {marker.popup && <Popup>{marker.popup}</Popup>}
  //       </Marker>
  //     ))}
  //   </Leaflet>
  // );
};

export default Map;
