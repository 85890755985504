import React, { useEffect, useLayoutEffect, useState } from "react";

import { IRankingDataRow } from "fullComponents/Rankings";
import { cutString } from "utils/string/string.utils";

interface IProps {
  data: IRankingDataRow[];
  currentClubId?: number;
}

const { REACT_APP_ICBAD_URL } = process.env;

const List: React.FC<IProps> = (props: IProps) => {
  const { data = [], currentClubId } = props;
  const [length, setLength] = useState(25);
  useLayoutEffect(() => {
    function updateSize() {
      const rankingsContainer = document.getElementsByClassName('rankings-container') as HTMLCollectionOf<HTMLElement>;
      const containerWidth = rankingsContainer.length > 0 ? rankingsContainer[0].getBoundingClientRect().width : 501;
      setLength(containerWidth / 20)
    }
    window.addEventListener('resize', updateSize);
    updateSize();
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Equipes</th>
          <th>Points</th>
        </tr>
      </thead>

      <tbody>
        {data.map((ranking) => (
          <tr key={ranking.Nom} className={ranking.ID === currentClubId ? "currentClub" : ""}>
            <td>{ranking.Rang}</td>
            <td>
              <a
                href={`${REACT_APP_ICBAD_URL}equipe/${ranking.ID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {length > 20 ?
                  cutString(ranking.Nom, length)
                  :
                  ranking.NomReduit
                }
              </a>
            </td>
            <td>{ranking.Points}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default List;
