import React, { ReactNode } from "react";

import { buildClassName } from "utils/dom/dom.utils";
import FlexContainer from "../FlexContainer";
import FlexContent from "../FlexContent";

interface IProps {
  children: ReactNode[];
  className?: string;
  style?: React.CSSProperties;
  biggerIndex: number;
}

const DEFAULT_BIGGER_INDEX = 0;

const MultiplesColumnsLayoutOneBig: React.FC<IProps> = (props: IProps) => {
  const {
    className,
    children,
    style,
    biggerIndex = DEFAULT_BIGGER_INDEX
  } = props;

  return (
    <FlexContainer className={className} style={style} flexDirection="row">
      {children.map((element, index) => {
        const itemClassName = buildClassName("", [
          [biggerIndex === index, "two-columns-one-big-item"]
        ]);

        return (
          <FlexContent key={index} className={itemClassName}>
            {element}
          </FlexContent>
        );
      })}
    </FlexContainer>
  );
};

export default MultiplesColumnsLayoutOneBig;
