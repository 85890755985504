import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  href: string;
  icon: any;
}

const SocialLink: React.FC<IProps> = (props: IProps) => {
  const { href, icon } = props;
  return (
    <a
      className="social-icon"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};

export default SocialLink;
