import React, { useMemo } from "react";
import moment from "moment";

import { cutString, getCDN, stripHtml } from "utils/string/string.utils";
import MultipleColumnsLayout from "layouts/MultipleColumnsLayout";
import Link from "components/Link";
import FlexImage from "components/FlexImage";
import { INewsProperty } from "../..";

const DATE_FORMAT = "dddd Do MMMM YYYY";
interface IProps {
  onOpenNews: (aNewsId: string) => void;
  news: INewsProperty;
}

const MAX_DESCRIPTION_LENGTH = 150;

const NewsRow: React.FC<IProps> = (props: IProps) => {
  const { news, onOpenNews } = props;
  const imageUrl = useMemo(
    () =>
      news.image.includes("http") ? news.image : `${getCDN()}${news.image}`,
    [news]
  );

  const leftPart = (
    <div className="news-container-left" onClick={() => onOpenNews(news.id)}>
      <FlexImage
        size="contain"
        src={imageUrl}
        style={{ backgroundPosition: "center" }}
        ariaLabel={news.title}
      />
    </div>
  );

  const rightPart = (
    <div className="news-container-right">
      <h3 className="news-container-right-title">{news.title}</h3>
      <div className="news-container-right-date">
        {moment(news.updatedAt).format(DATE_FORMAT)}
      </div>
      <div className="news-container-right-content">
        {cutString(
          stripHtml(news?.shortContent.replace(/(\/\w+>)/g, "$1 ")),
          MAX_DESCRIPTION_LENGTH
        )}
      </div>
      <Link title="Lire l'article" onClick={() => onOpenNews(news.id)}>
        Lire l&apos;article
      </Link>
    </div>
  );

  return (
    <MultipleColumnsLayout
      className="news-container-item"
      children={[leftPart, rightPart]}
      style={{ justifyContent: "center" }}
    />
  );
};

export default NewsRow;
