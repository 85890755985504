import React, { useEffect, useState } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";

interface IProps {
    equipments: any;
    center: any;
}


const MapComponent: React.FC<IProps> = (props: IProps) => {
    const { equipments = [], center = [0, 0] } = props


    const renderMarkers = () => {

        const data = equipments.filter((equipment: any) => equipment.latitude && equipment.longitude);
        if (!data.length) return;

        return data
            .map((equipment: any) => (
                <Marker key={equipment.equipmentId} position={[equipment.latitude, equipment.longitude]}>
                    <Popup>
                        <b>{equipment.name}</b>
                        <br />
                        {equipment.address && (
                            <span>
                                {equipment.address.floor}
                                {equipment.address.address}<br />
                                {equipment.address.postalCode}, {equipment.address.city}&nbsp;
                                {equipment.address.cedex},&nbsp;
                                {equipment.address.country}
                            </span>
                        )}
                        <br />
                        <a

                            href={`https://maps.google.com/?q=${equipment.latitude},${equipment.longitude}&z=3`}
                            target='_blank'
                        >
                            Ouvrir dans GMaps
                        </a>
                    </Popup>
                </Marker>
            ));
    }

    return (
        <div className="map" style={{ height: '300px', width: '51.5rem' }}>
            <Map center={center} zoom={12} scrollWheelZoom={true} >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {renderMarkers()}
            </Map>
        </div>
    );
};

export default MapComponent;