import React from "react";

import { Button } from "index";

interface IProps {
  labels: { successButtonLabel?: string; cancelButtonLabel?: string };
  onSuccess?: () => void;
}

const Footer: React.FC<IProps> = (props: IProps) => {
  const { labels, onSuccess } = props;
  return (
    <div className="modal__footer">
      {onSuccess && (
        <Button safetySpacing={false} onClick={onSuccess} isEditor>
          {labels.successButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default Footer;
