import React from "react";

import Skeleton from "components/Skeleton";

const Loader: React.FC = () => {
  return (
    <>
      <Skeleton shape="title" />
      <Skeleton shape="paragraph" repeat={3} />
    </>
  );
};

export default Loader;
