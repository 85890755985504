import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  children?: ReactNode;
  href?: string;
  onClick?: () => void;
  title?: string;
}

const Link: React.FC<IProps> = (props: IProps) => {
  const { children, href, onClick, title } = props;

  return (
    <a
      className="link-container"
      target="_blank"
      onClick={onClick}
      href={href}
      title={title}
      rel="noreferrer"
    >
      {children}&nbsp;
      <FontAwesomeIcon
        className="link-container__icon"
        icon={faChevronRight as any}
      />
    </a>
  );
};

export default Link;
