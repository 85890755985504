import React from "react";

// import { FOOTER_DEFAULT_PROPS } from "./editor.fields";
import Logo from "./components/Logo";
import Contact from "./components/Contact";

interface IProps {
  onMenuItemClick: (id: string) => void;
  displayLogo?: boolean;
  //displayAgenda?: boolean;
  displayContact?: boolean;
  //displayNews?: boolean;
}

const Footer: React.FC<IProps> = (props: IProps) => {
  const {
    displayLogo = true, //FOOTER_DEFAULT_PROPS.displayLogo,
    //displayAgenda = FOOTER_DEFAULT_PROPS.displayAgenda,
    displayContact = true, // FOOTER_DEFAULT_PROPS.displayContact,
    //displayNews = FOOTER_DEFAULT_PROPS.displayNews,
    onMenuItemClick,
  } = props;

  const rows: [boolean, React.FC, any?][] = [
    [displayLogo, Logo],
    [displayContact, Contact],
  ];

  return (
    <footer className="footer-main">
      {rows.map(
        ([shouldDisplay, Component, props = {}], index) =>
          shouldDisplay && (
            <div key={index} className="footer-main__item">
              <Component {...props} />
            </div>
          )
      )}
    </footer>
  );
};

export default Footer;
