import React, { useEffect, useState, useRef } from "react";

import NewsService from "service/news/news.service";
import { useWebsiteId } from "hooks";
import Heading from "./components/Heading";
import { INewsProperty } from "fullComponents/News";
import Loading from "components/Loading";
import NewsCard from "./components/NewsCard";
import Title from "components/Title";

interface IProps {
  onOpen: (aNewsId: string) => void;
  onError: () => void;
}

const TOTAL_NEWS = 20;

const DisplayAllNews: React.FC<IProps> = (props: IProps) => {
  const [news, setNews] = useState<INewsProperty[]>([]);
  const [loading, setLoading] = useState(true);
  const service = useRef(new NewsService());
  const websiteId = useWebsiteId();
  const { onOpen, onError } = props;

  useEffect(() => {
    const { promise, cancel } = service.current.all(websiteId, TOTAL_NEWS);
    promise
      .then((response) => {
        setNews(response);
        setLoading(false);
      })
      .catch(onError);
    return () => cancel();
  }, []);

  return (
    <div className="display-all-news">
      {/*<Heading />*/}
      <Title content="Nos Actualités" style={{ textAlign: "center", padding: "0" }} />

      {loading && <Loading />}
      {!loading && news.length === 0 && (
        <div className="display-all-news--empty">
          Il n'y a aucune actualité pour l'instant.
        </div>
      )}

      {!loading && news.length !== 0 && (
        <div className="display-all-news__container">
          {news.map((aNews) => (
            <NewsCard key={aNews.title} aNews={aNews} onOpen={onOpen} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DisplayAllNews;
