import React, { useMemo } from "react";
import moment from "moment";

import { INewsProperty } from "fullComponents/News";
import { getCDN } from "utils/string/string.utils";

const DATE_FORMAT = "dddd Do MMMM YYYY";
interface IProps {
  aNews: INewsProperty;
  onOpen: (aNewsId: string) => void;
}

const NewsCard: React.FC<IProps> = (props: IProps) => {
  const { aNews, onOpen } = props;
  const imageUrl = useMemo(
    () =>
      aNews.image.includes("http") ? aNews.image : `${getCDN()}${aNews.image}`,
    [aNews]
  );

  return (
    <div
      className="display-all-news__news-card"
      onClick={() => onOpen(aNews.id)}
    >
      <div
        className="display-all-news__news-card--background"
        style={{ backgroundImage: `url("${imageUrl}")` }}
      />
      <div className="display-all-news__news-card--title">{aNews.title}</div>
      <div className="display-all-news__news-card--date">
        {moment(aNews.updatedAt).format(DATE_FORMAT)}
      </div>
    </div>
  );
};

export default NewsCard;
