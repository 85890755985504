import React from "react";

import Carousel from "fullComponents/Carousel";
// import FloatingCalendar from "fullComponents/FloatingCalendar";
import { CAROUSELCALENDAR_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  autoPlay?: boolean;
  onClick: (aNewsId: string) => void;
}

const CarouselCalendar: React.FC<IProps> = (props: IProps) => {
  const { autoPlay = CAROUSELCALENDAR_DEFAULT_PROPS.autoPlay, onClick } = props;
  return (
    <div className="carousel-calendar-container">
      <Carousel autoPlay={autoPlay} onClick={onClick} />
      {/* TODO: commented for now, don't remove it!!!! <FloatingCalendar /> */}
    </div>
  );
};

export default CarouselCalendar;
