import React from "react";
import moment from "moment";

import { IMeetingData } from "fullComponents/Meetings/meetings.interfaces";
import TeamLink from "../TeamLink";

interface IProps {
  data: IMeetingData;
}

const MeetingDetails: React.FC<IProps> = (props: IProps) => {
  const {
    data: {
      Equipe1,
      Equipe2,
      Lieu: { Date },
    },
  } = props;

  return (
    <p className="meetings-container--text">
      Résultat de la rencontre entre&nbsp;
      <TeamLink team={Equipe1} /> et&nbsp;
      <TeamLink team={Equipe2} /> le&nbsp;
      {formatDate(Date)} :
    </p>
  );
};

export default MeetingDetails;

const formatDate = (date: string): string => moment(date).format("DD/MM/YYYY");
