import PageBuilder, { IPageComponentProperties } from "../pageBuilder";

class EditorRemover {
  private editor: PageBuilder;

  constructor(editor: PageBuilder) {
    this.editor = editor;
  }

  get components(): IPageComponentProperties[] | undefined {
    return this.editor.properties.components;
  }

  set components(values: IPageComponentProperties[] | undefined) {
    this.editor.properties.components = values;
  }

  public remove = (componentId: string) => {
    const components = this.components || [];
    this.components = components.filter(
      (component) => component.id !== componentId
    );
  };
}

export default EditorRemover;
