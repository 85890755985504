import React, { useEffect, useState } from "react";

import { useGalleryService } from "hooks";
import { IAlbum, IImage } from "service/service.interfaces";
import Mansory, { IMansoryPhoto } from "./components/Mansory";
import Title from "components/Title";
import Loading from "components/Loading";
import DisplayAlbums from "./components/DisplayAlbums";
import EmptyMessage from "./components/EmptyMessage";
import { getCDN } from "utils/string/string.utils";

interface IProps {
  albumId: string;
  canSelectAlbums?: boolean;
}

const Albums: React.FC<IProps> = (props: IProps) => {
  const { albumId, canSelectAlbums = false } = props;
  const galleryService = useGalleryService();
  const [album, setAlbum] = useState<IAlbum>();
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState<IImage[]>();
  const [currentAlbumId, setCurrentAlbumId] = useState<string>(albumId);

  useEffect(() => {
    if (!currentAlbumId) {
      return;
    }

    setLoading(true);

    const { promise, cancel } = galleryService.find(currentAlbumId);
    promise
      .then(setAlbum)
      .catch(() => {
        // Nothing.
      })
      .finally(() => setLoading(false));
    return () => cancel();
    // eslint-disable-next-line
  }, [currentAlbumId]);

  useEffect(() => {
    if (!currentAlbumId) {
      return;
    }

    const { promise, cancel } = galleryService.findPictures(currentAlbumId);
    promise.then(setPictures);
    return () => cancel();
    // eslint-disable-next-line
  }, [currentAlbumId]);

  if (!currentAlbumId) {
    return (
      <div style={{ textAlign: "center", paddingBottom: "20px" }}>
        <EmptyMessage />
      </div>
    );
  }

  if (!album) {
    if (loading) {
      return <Loading />;
    }

    return null;
  }

  return (
    <div className="album">
      <Title className="album--title" content={album.title} />

      {canSelectAlbums && (
        <DisplayAlbums
          onChange={setCurrentAlbumId}
          currentAlbumId={currentAlbumId}
        />
      )}

      {pictures && pictures.length !== 0 && (
        <Mansory photos={formatPicture(pictures)} />
      )}
    </div>
  );
};

const formatPicture = (pictures: IImage[] = []): IMansoryPhoto[] => {
  return pictures.map((picture) => ({
    key: picture.id,
    src: `${getCDN()}${picture.path}`,
    width: 0,
    height: 0,
    alt: picture.title,
  }));
};

export default Albums;
