import React, { useState, useCallback } from "react";

interface IProps {
    index: number,
    photo: any,
    left: number,
    top: number,
    key: string,
    margin: string
    onClick: (photo: any, index: number) => void
}

const CustomImageRenderer: React.FC<IProps> = (props: IProps) => {
    const { index, photo, left, top, key, margin, onClick } = props;

    return (
        <div
            style={{
                margin,
                height: "225px",
                width: "225px",
                padding: "15px",
                backgroundImage: `url(${photo.src})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundOrigin: "content-box"
            }}
            className={"img"}
            onClick={() => onClick(photo, index)}
        >
        </div>
    );
};

export default CustomImageRenderer;