import React, { ReactNode } from "react";

import FlexContainer from "../FlexContainer";
import FlexContent from "../FlexContent";

interface IProps {
  children: ReactNode[];
  className?: string;
  style?: React.CSSProperties;
  firstChildNoFlex?: boolean;
  flexDirection?: "row" | "column";
  alignItems?: "flex-start" | "flex-end" | "center";
}

const MultipleColumnsLayout: React.FC<IProps> = (props: IProps) => {
  const { className, children = [], style, flexDirection, alignItems, firstChildNoFlex } = props;
  const mainProps = { className, style };

  return (
    <div className="multiple-column-layout" data-firstchildnoflex={firstChildNoFlex}>
      <FlexContainer
        {...mainProps}
        flexDirection={flexDirection}
        alignItems={alignItems}
      >
        {children.map((element, index) => {
          return <FlexContent key={index}>{element}</FlexContent>;
        })}
      </FlexContainer>
    </div>
  );
};

export default MultipleColumnsLayout;
