import React from "react";
import { useBreakpoints } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { IPrice } from "service/service.interfaces";
import { cutString } from "utils/string/string.utils";

interface IProps {
  price: IPrice;
  categories: { title: string; description: string }[];
}

const MAX_TITLE_LENGTH = 40;

const DisplayPrice: React.FC<IProps> = (props: IProps) => {
  const { price, categories = [] } = props;
  const { XS } = useBreakpoints();

  return (
    <div className="price-item">
      <div className="price-item__heading">
        <h2 className="price-item__heading--title u-center" title={price.name}>
          {cutString(price.name, MAX_TITLE_LENGTH)}
        </h2>

        <div className="price-item__heading--price u-center">
          {price.price}&euro;
        </div>
      </div>

      {XS && <MobileDisplay price={price} />}
      {!XS && <DesktopDisplay price={price} categories={categories} />}
    </div>
  );
};

export default DisplayPrice;

const MobileDisplay = (props: { price: IPrice }): JSX.Element => {
  const { price } = props;

  if (!price.discounts.length && !price.options.length) {
    return <></>;
  }

  return (
    <div className="price-item__body">
      {price.discounts.map((discount) => (
        <div key={discount.discountId} className="price-item__body--row">
          {discount.name} (réduc.)
        </div>
      ))}

      {price.options.map((option) => (
        <div key={option.optionId} className="price-item__body--row">
          {option.name} (option)
        </div>
      ))}
    </div>
  );
};

const DesktopDisplay = (props: {
  price: IPrice;
  categories: { title: string; description: string }[];
}): JSX.Element => {
  const { price, categories } = props;

  return (
    <div className="price-item__body">
      {categories.map((category) => {
        const isIncluded = priceIncludeCategory(price, category.title);

        return (
          <div key={category.title} className="price-item__body--row">
            {isIncluded && <div className="price-item__body--row-bubble" />}
            {!isIncluded && (
              <div className="price-item__body--row-times">
                <FontAwesomeIcon icon={faTimes as any} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const priceIncludeCategory = (price: IPrice, category: string): boolean => {
  const predicate = (p: { name: string }) => p.name === category;
  return (
    price.discounts.find(predicate) !== undefined ||
    price.options.find(predicate) !== undefined
  );
};
