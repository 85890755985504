import React from "react";

interface IProps {
  title: string;
}

const ContactHeader: React.FC<IProps> = (props: IProps) => {
  const { title } = props;

  return (
    <div className="contact-image-container">
      <div className="contact-image" />
      <h1 className="contact-image-title">{title}</h1>
    </div>
  );
};

export default ContactHeader;
