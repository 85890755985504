import React, { useState, useEffect } from "react";

import InstanceService from "service/instance";
import useInstanceId from "hooks/useInstanceId";
import ChevronNavigation from "components/ChevronNavigation";
import Loader from "./components/Loader";
import MeetingDetails from "./components/MeetingDetails";
import BottomDisplay from "./components/BottomDisplay";
import { IMeetingData } from "./meetings.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const service = new InstanceService();
const DEFAULT_INDEX = 0;

interface IProps {
  setMeetingsIsEmpty?: Function;
}
const { REACT_APP_ICBAD_URL } = process.env;

const Meetings: React.FC<IProps> = (props: IProps) => {
  const { setMeetingsIsEmpty = () => {} } = props;
  const instanceId = useInstanceId();
  const [meetingData, setMeetingData] = useState<IMeetingData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(DEFAULT_INDEX);
  const currentMeetingData = meetingData[index];

  useEffect(() => {
    const { promise, cancel } = service.meetings(instanceId);
    promise.then((response) => {
      setMeetingData(response);
      setIsLoading(false);
      setMeetingsIsEmpty(!!!currentMeetingData);
    });
    return (): void => cancel();
  }, [instanceId]);

  const handleOnPrevious = (): void => {
    const newIndex = index === 0 ? meetingData.length - 1 : index - 1;
    setIndex(newIndex);
  };

  const handleOnNext = (): void => {
    const newIndex = index === meetingData.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
  };

  return (
    <div className="meetings-container" data-content={!!currentMeetingData}>
      {isLoading && <Loader />}

      {!isLoading && currentMeetingData && (
        <>
          <ChevronNavigation
            title={currentMeetingData.Competition.Nom}
            onPrevious={handleOnPrevious}
            onNext={handleOnNext}
            hasArrow={meetingData.length > 1}
            extra={
              <a
                href={`${REACT_APP_ICBAD_URL}competition/${currentMeetingData.Competition.Autorisation}/rencontre/${currentMeetingData.ID_Rencontre}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInfoCircle as any} />
              </a>
            }
          />

          <MeetingDetails data={currentMeetingData} />
          <BottomDisplay data={currentMeetingData} />
        </>
      )}
    </div>
  );
};

export default Meetings;
