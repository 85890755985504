import MasterService from "service/master.service";
import LocalStorageService from "service/localStorage/localStorage.service";
import { INewsProperty } from "fullComponents/News";

class NewsService {
  private master: MasterService;
  private localStorage: LocalStorageService;

  constructor() {
    this.master = new MasterService();
    this.localStorage = new LocalStorageService();
  }

  get ownerId(): string {
    return this.localStorage.get("iid") as string;
  }

  all = (
    websiteId: string,
    maxItems = 5
  ): { promise: Promise<INewsProperty[]>; cancel: () => void } => {
    return this.master.get<INewsProperty[]>(
      `websites/${websiteId}/news?maxItems=${maxItems}`
    );
  };

  find = (
    websiteId: string,
    id: string
  ): { promise: Promise<INewsProperty>; cancel: () => void } => {
    return this.master.get<INewsProperty>(`websites/${websiteId}/news/${id}`);
  };

  carrousel = (
    websiteId: string
  ): { promise: Promise<INewsProperty[]>; cancel: () => void } => {
    return this.master.get<INewsProperty[]>(
      `websites/${websiteId}/news?highlighted`
    );
  };
}

export default NewsService;
