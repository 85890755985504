import React, { CSSProperties } from "react";

import { buildClassName } from "utils/dom/dom.utils";
import SafetySpacing from "layouts/SafetySpacing";
import { FLEXIMAGE_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  src?: string;
  size?: "cover" | "contain";
  ariaLabel?: string;
  style?: CSSProperties;
}

const FlexImage: React.FC<IProps> = (props: IProps) => {
  const {
    src = FLEXIMAGE_DEFAULT_PROPS.src,
    size = FLEXIMAGE_DEFAULT_PROPS.size,
    ariaLabel = FLEXIMAGE_DEFAULT_PROPS.ariaLabel,
    style = { height: FLEXIMAGE_DEFAULT_PROPS["style.height"] },
  } = props;
  const className = buildClassName("flex-image", [[`flex-image--${size}`]]);

  return (
    <SafetySpacing>
      <div
        role="img"
        aria-label={ariaLabel}
        className={className}
        style={{
          backgroundImage: `url(${
            src && src.length ? src : FLEXIMAGE_DEFAULT_PROPS.src
          })`,
          ...style,
        }}
      />
    </SafetySpacing>
  );
};

export default FlexImage;
