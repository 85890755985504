import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onSubmit: (crendetials: any) => Promise<any>;
}

const ConnectionForm: React.FC<IProps> = (props: IProps) => {
  const { onSubmit } = props;
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    const credentials = {
      licence: e.target[0].value,
      password: e.target[1].value,
    };
    onSubmit(credentials)
      .then((res) => setIsLoading(false))
      .catch((err) => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="connection-form">
        <div className="connexion-form-title-container">
          <div>
            Vous êtes déjà inscrit dans ce club ou un autre club affilié à la
            fédération Française de Badminton et vous souhaitez renouveler ?
            <br />
            Connectez-vous pour accéder au formulaire de renouvellement de
            licence.
          </div>
        </div>
        <form className="form" onSubmit={handleOnSubmit}>
          <input
            name="login"
            type="text"
            className="input-login"
            placeholder="Numéro de licence"
          />
          <input
            name="password"
            type="password"
            className="input-password"
            placeholder="Mot de passe"
          />
          <button className="button">
            {isLoading && (
              <>
                <FontAwesomeIcon icon={faSpinner as any} pulse />
                &nbsp;
              </>
            )}
            Se connecter
          </button>
          {error && (
            <div className="error">
              Oups ! Ce ne sont visiblement pas les bons identifiants.
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default ConnectionForm;
