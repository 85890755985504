import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onPrevious: () => void;
  onNext: () => void;
  title: string;
  hasArrow: boolean;
  extra?: ReactNode;
}

const ChevronNavigation: React.FC<IProps> = (props: IProps) => {
  const { onPrevious, onNext, title, hasArrow, extra } = props;

  return (
    <div className="chevron-navigation">
      <div className="chevron-navigation__navigation">
        {hasArrow && (
          <Arrow title="Précédent" onClick={onPrevious} icon={faChevronLeft} />
        )}

        <h3 className="chevron-navigation--title">
          {title}
          {extra && <>&nbsp;{extra}</>}
        </h3>

        {hasArrow && (
          <Arrow title="Suivant" onClick={onNext} icon={faChevronRight} />
        )}
      </div>
    </div>
  );
};

const Arrow = (props: {
  title: string;
  onClick: () => void;
  icon: IconDefinition;
}): JSX.Element => (
  <div
    className="chevron-navigation--navigation-arrow"
    title={props.title}
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={props.icon as any} />
  </div>
);

export default ChevronNavigation;
