import React, { useEffect, useState } from "react";

import NewsService from "service/news/news.service";
import { useWebsiteId } from "hooks";
import MultipleColumnsLayout from "layouts/MultipleColumnsLayout";
import Button from "components/Button";
import NewsRow from "./components/NewsRow";
import Title from "components/Title";
import SkeletonNews from "components/SkeletonNews";
import { NEWS_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  onShowAllNews: () => void;
  onOpenNews: (aNewsId: string) => void;
  maxItems?: number;
  title?: string;
}

export interface INewsProperty {
  id: string;
  title: string;
  shortContent: string;
  image: string;
  href: string;
  highlighted: boolean;
  createdAt: string;
  updatedAt: string;
  websiteId: string;
}

const service = new NewsService();

const News: React.FC<IProps> = (props: IProps) => {
  const {
    onShowAllNews,
    onOpenNews,
    maxItems = NEWS_DEFAULT_PROPS.maxItems,
    title = NEWS_DEFAULT_PROPS.title,
  } = props;
  const [news, setNews] = useState<INewsProperty[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const websiteId = useWebsiteId();

  useEffect(() => {
    const { promise, cancel } = service.all(websiteId, maxItems);
    promise.then((response) => {
      setNews(response);
      setIsLoading(false);
    });
    return () => cancel();
  }, [maxItems, websiteId]);

  return (
    <div className="news-container">
      <Title content={title} />

      {isLoading && (
        <>
          <SkeletonNews />
          <SkeletonNews />
        </>
      )}
      {!isLoading && (
        <>
          <MultipleColumnsLayout
            flexDirection="column"
            children={news.map((aNews) => (
              <NewsRow key={aNews.href} news={aNews} onOpenNews={onOpenNews} />
            ))}
          />

          <div className="news-container__footer">
            <Button safetySpacing={false} onClick={onShowAllNews}>
              Voir toutes les actualités
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default News;
