import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { useInstanceId } from "hooks";
import SafetySpacing from "layouts/SafetySpacing";
import calendarConfig from "./calendar.config";
import { CALENDARACTIVITY_DEFAULT_PROPS } from "./editor.fields";
import InstanceService from "service/instance";

const localizer = momentLocalizer(moment);

interface IProps {
  view?: "month" | "week" | "day";
  toolbar?: boolean;
  height?: number;
  step?: number;
}

const service = new InstanceService();

// http://jquense.github.io/react-big-calendar/examples/index.html
const CalendarActivity: React.FC<IProps> = (props: IProps) => {
  const {
    view = CALENDARACTIVITY_DEFAULT_PROPS.view,
    toolbar = CALENDARACTIVITY_DEFAULT_PROPS.toolbar,
    height = CALENDARACTIVITY_DEFAULT_PROPS.height,
    step = CALENDARACTIVITY_DEFAULT_PROPS.step,
  } = props;
  const [calendarEvents, setCalendarEvents] = useState<any[]>([]);
  const [currentView, setCurrentView] = useState(view);
  const [currentDate, setCurrentDate] = useState(new Date());
  const instanceId = useInstanceId();

  useEffect(() => {
    setCalendarEvents([]);

    const { promise, cancel } = service.activities(instanceId, {
      view: currentView,
      date: moment(currentDate).format("YYYY-MM-DD"),
    });

    promise.then(setCalendarEvents).catch((err) => console.error(err));
    return () => cancel();
  }, [currentView, currentDate, instanceId]);

  return (
    <SafetySpacing>
      <div className="calendar-container" style={{ height }}>
        <Calendar
          localizer={localizer}
          {...calendarConfig}
          toolbar={toolbar}
          events={calendarEvents}
          step={step}
          defaultView={view}
          onNavigate={(date: Date) => setCurrentDate(date)}
          onView={(view: any) => setCurrentView(view)}
        />
      </div>
    </SafetySpacing>
  );
};

export default CalendarActivity;
