import React, { useEffect, useState } from "react";

import InstanceService from "service/instance";
import useInstanceId from "hooks/useInstanceId";
import Loader from "../Loader";
import LocationLink from "./Components/LocationLink";
import { IEquipmentData } from "fullComponents/Equipments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useBreakpoints } from "hooks";

const service = new InstanceService();

export interface ISlotsData {
  day: string;
  start: string;
  end: string;
  type: string;
  public: string;
  population: any;
  supervisor: any;
  equipmentId: string;
}

interface IProps {
  setLatitude?: (latitude: any) => void;
  setLongitude?: (longitude: any) => void;
  isSlotsEquipments?: boolean;
}

const Slots: React.FC<IProps> = (props: IProps) => {
  const { setLatitude, setLongitude, isSlotsEquipments = false } = props;
  const [slotsData, setSlotsData] = useState<ISlotsData[]>([]);
  const [equipmentsData, setEquipmentsData] = useState<IEquipmentData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const instanceId = useInstanceId();
  const { SM, XS } = useBreakpoints();

  const handleOnSelectEquipment = (equipment: any) => {
    if (setLatitude && setLongitude) {
      setLatitude(equipment.latitude);
      setLongitude(equipment.longitude);
    }
  };

  useEffect(() => {
    const { promise, cancel } = service.slots(instanceId);
    promise.then((response) => {
      const sortedResponse = response.sort(function (a, b) {
        return parseInt(a.day) - parseInt(b.day);
      });
      setSlotsData(sortedResponse);
      setIsLoading(false);
      service.equipments(instanceId).promise.then((response) => {
        setEquipmentsData(response.equipments);
      });
    });
    return () => cancel();
  }, [instanceId]);

  const getEquipmentById = (id: string) => {
    let result = null;
    equipmentsData.forEach((equipment) => {
      if (equipment.equipmentId) {
        result = equipment;
      }
    });
    return result;
  };

  const getDay = (day: string) => {
    const data = {
      1: "Lundi",
      2: "Mardi",
      3: "Mercredi",
      4: "Jeudi",
      5: "Vendredi",
      6: "Samedi",
      7: "Dimanche",
    };
    return data[day] || "-";
  };

  const MobileDisplay = (): JSX.Element => {
    return (
      <div className="slots-container">
        {isLoading && <Loader />}

        {!isLoading && slotsData && Object.keys(slotsData).length !== 0 && (
          <>
            <table style={{ width: "100%" }}>
              <thead className="slots-header">
                <tr>
                  <th className="header-element">Date : Début - Fin</th>
                  <th className="header-element">Type</th>
                  <th className="header-element">Lieu</th>
                </tr>
              </thead>

              <tbody>
                {slotsData.map((slot) => (
                  <tr key={slot.equipmentId + slot.day + slot.start + slot.end}>
                    <td className="content-element">{`${getDay(slot.day)}: ${
                      slot.start
                    } - ${slot.end}`}</td>
                    <td className="content-element">
                      {slot.type || <p className="empty-field">-</p>}
                    </td>
                    {isSlotsEquipments ? (
                      <td
                        className="content-element"
                        onClick={() =>
                          handleOnSelectEquipment(
                            getEquipmentById(slot.equipmentId)
                          )
                        }
                      >
                        Voir sur la carte &nbsp;
                        <FontAwesomeIcon icon={faMapMarkerAlt as any} />
                      </td>
                    ) : (
                      <td className="content-element">
                        {
                          <LocationLink
                            equipment={getEquipmentById(slot.equipmentId)}
                          ></LocationLink>
                        }
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  };

  const DesktopDisplay = (): JSX.Element => {
    return (
      <div className="slots-container">
        {isLoading && <Loader />}

        {!isLoading && slotsData && Object.keys(slotsData).length !== 0 && (
          <>
            <table style={{ width: "100%" }}>
              <thead className="slots-header">
                <tr>
                  <th className="header-element">Date : Début - Fin</th>
                  <th className="header-element">Type</th>
                  <th className="header-element">Offre de pratique</th>
                  <th className="header-element">Population</th>
                  <th className="header-element">Encadrant</th>
                  <th className="header-element">Lieu</th>
                </tr>
              </thead>

              <tbody>
                {slotsData.map((slot) => (
                  <tr key={slot.equipmentId + slot.day + slot.start + slot.end}>
                    <td className="content-element">{`${getDay(slot.day)}: ${
                      slot.start
                    } - ${slot.end}`}</td>
                    <td className="content-element">
                      {slot.type || <p className="empty-field">-</p>}
                    </td>
                    <td className="content-element">
                      {slot.public || <p className="empty-field">-</p>}
                    </td>
                    <td className="content-element">
                      {slot.population && slot.population.length !== 0 ? (
                        slot.population.join(", ")
                      ) : (
                        <p className="empty-field">-</p>
                      )}
                    </td>
                    <td className="content-element">
                      {slot.supervisor ? (
                        `${slot.supervisor.lastName || "-"} ${
                          slot.supervisor.firstName || ""
                        }`
                      ) : (
                        <p className="empty-field">-</p>
                      )}
                    </td>
                    {isSlotsEquipments ? (
                      <td
                        className="content-element"
                        onClick={() =>
                          handleOnSelectEquipment(
                            getEquipmentById(slot.equipmentId)
                          )
                        }
                      >
                        Voir sur la carte &nbsp;
                        <FontAwesomeIcon icon={faMapMarkerAlt as any} />
                      </td>
                    ) : (
                      <td className="content-element">
                        {
                          <LocationLink
                            equipment={getEquipmentById(slot.equipmentId)}
                          ></LocationLink>
                        }
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {(SM || XS) && <MobileDisplay />}
      {!SM && !XS && <DesktopDisplay />}
    </>
  );
};

export default Slots;
