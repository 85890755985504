import PageBuilder, { IPageProperties } from "../pageBuilder";
import { findComponentById } from "../utils/editor.utils";
import { IPageComponentProperties } from "index";
import { buildNestedProps } from "utils/object/object.utils";
import { ReactNode } from "react";

class EditorUpdater {
  private editor: PageBuilder;

  constructor(editor: PageBuilder) {
    this.editor = editor;
  }

  get components(): IPageComponentProperties[] | undefined {
    return this.editor.properties.components;
  }

  public updateComponentById = (
    componentId: string,
    newProps: any
  ): { elements: ReactNode[]; pageProperties: IPageProperties } => {
    const aComponent = findComponentById(componentId, this.components);

    if (!aComponent) {
      console.error("[EDITOR|UPDATE] Unable to find component", {
        componentId,
      });
    } else {
      aComponent.props = buildNestedProps(newProps);
    }

    return this.buildOutput();
  };

  private buildOutput = (): {
    elements: ReactNode[];
    pageProperties: IPageProperties;
  } => {
    return {
      elements: this.editor.build(),
      pageProperties: this.editor.properties,
    };
  };
}

export default EditorUpdater;
