import { IEditorField } from "utils/editor/components.fields";
import getDefaultEditableFields from "core/types/editorDefaultFields";
import { ReactNode } from "react";

const fields: IEditorField[] = [
  {
    id: "children",
    label: "Texte",
    type: "text",
    required: true,
  },
  {
    id: "disabled",
    label: "Désactivé",
    type: "checkbox",
    required: false,
  },
  {
    id: "align",
    label: "Alignement",
    type: "select",
    selectItems: [
      { key: "left", label: "Gauche" },
      { key: "center", label: "Milieu" },
      { key: "right", label: "Droite" },
    ],
    required: false,
  },
  ...getDefaultEditableFields(["width", "height"]),
  {
    id: "url",
    label: "Lien",
    type: "text",
    required: true,
  },
];

export const BUTTON_DEFAULT_PROPS: {
  children: ReactNode;
  type: "primary" | "secondary";
  buttonType: "button" | "reset" | "submit";
  disabled: boolean;
  url: string;
  align: "left" | "center" | "right";
} = {
  children: "Cliquez ici",
  type: "primary",
  buttonType: "button",
  disabled: false,
  url: "Lien",
  align: "left",
};

export default fields;
