import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const EmptyMessage: React.FC = () => {
  return (
    <div className="prices--empty u-center">
      <FontAwesomeIcon icon={faInfoCircle as any} />
      &nbsp;{`Il n'y a aucun tarif pour le moment.`}
    </div>
  );
};

export default EmptyMessage;
