import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "url",
    label: "Lien YouTube",
    type: "text",
    required: true,
  },
];

export default fields;
