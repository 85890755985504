import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IInterclubTeam } from "service/service.interfaces";
import Button from "components/Button";

interface IProps {
  team: IInterclubTeam;
  onClick: () => void;
  selected: boolean;
}

const Team: React.FC<IProps> = (props: IProps) => {
  const { team, onClick, selected } = props;

  return (
    <Button
      onClick={onClick}
      safetySpacing={false}
      type={selected ? "primary" : "secondary"}
    >
      {selected && (
        <>
          <FontAwesomeIcon icon={faCheck as any} />
          &nbsp;
        </>
      )}
      {team.Competition.Nom}
    </Button>
  );
};

export default Team;
