import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "autoPlay",
    label: "Défilement automatique",
    type: "checkbox",
    required: false,
  },
];

export const CAROUSELCALENDAR_DEFAULT_PROPS = {
  autoPlay: false,
};

export default fields;
