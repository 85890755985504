import React, { useState } from "react";
import Button from "components/Button";
import InstanceService from "service/instance";
import { useInstanceId } from "hooks";

interface IProps {
}

const instanceService = new InstanceService()

const SubscribeForm: React.FC<IProps> = (props: IProps) => {
  const instanceId = useInstanceId();
  const [button, setButton] = useState<string>('btn1')

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.target[0].value;
    if (button === 'btn1') {
      instanceService.addToNewsletter(instanceId, email)
    } else {
      instanceService.removeFromNewsletter(instanceId, email)
    }
  };

  return (
    <div className="subscribe-now-form">
      <form onSubmit={handleOnSubmit} className="form-group">
        <div className="input-and-button">
          <input
            id="e-mail"
            type="email"
            placeholder="Adresse e-mail"
            required
            className="email"
          />
          <div className="buttons">
            <input className="button" type="submit" onClick={() => setButton('btn1')} value="Me tenir au courant" />
            <input className="button" type="submit" onClick={() => setButton('btn2')} value="Me désabonner" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubscribeForm;
