import React from "react";

import Link from "components/Link";
import { getCDN } from "utils/string/string.utils";

interface IProps {
  linkUrl: string;
  linkTitle: string;
}

const QuickAccessLink: React.FC<IProps> = (props: IProps) => {
  const { linkUrl, linkTitle } = props;

  return (
    <div className="quick-access--item">
      <Link
        href={linkUrl.includes("http") ? linkUrl : getCDN() + "" + linkUrl}
        title={linkTitle}
      >
        - {linkTitle}
      </Link>
    </div>
  );
};

export default QuickAccessLink;
