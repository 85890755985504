import React, { CSSProperties } from "react";

import { buildClassName } from "utils/dom/dom.utils";
import SafetySpacing from "layouts/SafetySpacing";
import { TITLE_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  content: string;
  firstWordColor?: string;
  className?: string;
  safetySpacing?: boolean;
  style?: CSSProperties;
}

const SEPARATOR = " ";

const Title: React.FC<IProps> = (props: IProps) => {
  const {
    content = TITLE_DEFAULT_PROPS.content,
    firstWordColor,
    className,
    safetySpacing = true,
    style
  } = props;
  const [firstWords, ...otherWords] = content.split(SEPARATOR);
  const mainClassName = buildClassName("title-container", [[className]]);

  return (
    <SafetySpacing active={safetySpacing}>
      <div className={mainClassName} style={{ ...style }}>
        <span
          className="title-container--main"
          style={{ color: firstWordColor }}
        >
          {firstWords}
        </span>
        &nbsp;
        <span className="title-container--sub">
          {otherWords.join(SEPARATOR)}
        </span>
      </div>
    </SafetySpacing>
  );
};

export default Title;
