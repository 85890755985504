import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  active?: boolean;
}

const SafetySpacing = (props: IProps) => {
  const { children, active = true } = props;

  if (!active) {
    return <>{children}</>;
  }

  return <div className="safety-spacing">{children}</div>;
};

export default SafetySpacing;
