import React, { useMemo } from "react";
import { getCDN } from "utils/string/string.utils";

type Props = {
  image?: string;
};

const Heading: React.FC<Props> = (props) => {
  const { image = "" } = props;
  const imageUrl = useMemo(
    () => (image.includes("http") ? image : `${getCDN()}${image}`),
    [image]
  );

  return (
    <div
      className="display-news__heading"
      style={{ backgroundImage: `url("${imageUrl}")`, width: "100%" }}
    />
  );
};

export default Heading;
