import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ErrorMessage: React.FC = () => {
  return (
    <div className="prices--error u-center">
      <FontAwesomeIcon icon={faExclamationTriangle as any} />
      &nbsp;Impossible de récupérer les tarifs pour le moment.
    </div>
  );
};

export default ErrorMessage;
