import { IPageComponentProperties } from "index";
import { ComponentTypes } from "../components.mapping";

export const getTopLevelComponent = (
  components: IPageComponentProperties[] | undefined = []
): IPageComponentProperties | undefined => {
  return components.find((component) => !component.parentId);
};

export const findChildOfComponent = (
  parentId: string,
  components: IPageComponentProperties[] | undefined = []
): IPageComponentProperties[] => {
  return components.filter((component) => component.parentId === parentId);
};

export const findComponentById = (
  componentId: string,
  components: IPageComponentProperties[] | undefined = []
): IPageComponentProperties | undefined => {
  return components.find((aComponent) => aComponent.id === componentId);
};

export const findPropsOf = (
  componentId: string,
  components: IPageComponentProperties[] | undefined = []
): object | undefined => {
  const aComponent = findComponentById(componentId, components);
  return aComponent ? aComponent.props : undefined;
};

export const getParentIdOf = (
  componentId: string,
  components: IPageComponentProperties[] | undefined = []
): string | undefined => {
  return components?.find((component) => component.id === componentId)
    ?.parentId;
};

export const isTopLevel = (component: IPageComponentProperties): boolean =>
  component.parentId === undefined;

export const oneChildrenIsSection = (
  components: IPageComponentProperties[] | undefined = [],
  sectionTypes: ComponentTypes[]
): boolean => {
  let result = false
  components.forEach(element => {
    if (sectionTypes.includes(element.type))
      result = true;
  });
  return result
};
