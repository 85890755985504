import { IEditorField } from "utils/editor/components.fields";

const fields: IEditorField[] = [
  {
    id: "title",
    label: "Titre",
    type: "text",
    required: false,
  },
  {
    id: "priceByRow",
    label: "Nombre de prix par ligne",
    type: "number",
    required: false,
  },
];

export const PRICES_DEFAULT_PROPS = {
  title: "Nos tarifs",
  priceByRow: 3,
};

export default fields;
