// IDs from HyperCube.
export enum PlayerRoles {
  CAPTAIN = 1,
  SUBSTITUTE = 2,
  REFEREE = 3,
  TITULAR = 4,
  REPLACEMENT = 5,
  NONE = "",
}

export const PlayerRoleLabels: {
  [x: string]: string;
} = {
  [PlayerRoles.CAPTAIN]: "Capitaine",
  [PlayerRoles.SUBSTITUTE]: "Suppléant",
  [PlayerRoles.REFEREE]: "Arbitre",
  [PlayerRoles.TITULAR]: "Joueur titulaire",
  [PlayerRoles.REPLACEMENT]: "Joueur remplaçant",
  [PlayerRoles.NONE]: "Joueur",
};
