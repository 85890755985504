import { cutString } from "utils/string/string.utils";

const SHORT_MONTH_MAX_CHARS = 3;
const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
];

export const extractDayOfMonth = (date: Date): number => {
  return date.getDate();
};

export const extractMonth = (date: Date, short: boolean = false): string => {
  const monthIndex = date.getMonth();
  const monthString = MONTHS[monthIndex];

  return short
    ? cutString(monthString, SHORT_MONTH_MAX_CHARS, ".")
    : monthString;
};
