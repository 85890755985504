import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import InstanceService from "service/instance";
import useInstanceId from "hooks/useInstanceId";
import ChevronNavigation from "components/ChevronNavigation";
import Loader from "./components/Loader";
import List from "./components/List";

export interface IRankingData {
  ID_Equipe: number;
  Nom: string;
  Saison: number;
  Competition: {
    ID: number;
    Nom: string;
    Sigle: string;
    Autorisation: number;
  };
  Tableau: {
    ID: number;
    Nom: string;
  };
  Clubs: [
    {
      ID_Club: number;
      Sigle: string;
      Nom: string;
      CodePostal: string;
      Ville: string;
    }
  ];
  Classements: IRankingDataRow[];
}

export interface IRankingDataRow {
  ID: number;
  Nom: string;
  NomReduit: string;
  Jouees: number;
  Gagnees: number;
  Nulles: number;
  Perdues: number;
  Forfaits: number;
  Bonus: number;
  Sanctions: number;
  Points: number;
  Rang: number;
}

interface IProps {
  setRankingsIsEmpty?: Function;
  currentClubId?: number;
}

const service = new InstanceService();
const DEFAULT_INDEX = 0;
const { REACT_APP_ICBAD_URL } = process.env;

const Rankings: React.FC<IProps> = (props: IProps) => {
  const { setRankingsIsEmpty = () => {}, currentClubId } = props;
  const [rankingData, setRankingData] = useState<IRankingData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(DEFAULT_INDEX);
  const instanceId = useInstanceId();
  const currentRankinData = rankingData[index];

  useEffect(() => {
    const { promise, cancel } = service.rankings(instanceId);
    promise.then((response) => {
      setRankingData(response);
      setIsLoading(false);
      setRankingsIsEmpty(!!!currentRankinData);
    });
    return () => cancel();
  }, [instanceId]);

  const handleOnPrevious = () => {
    const newIndex = index === 0 ? rankingData.length - 1 : index - 1;
    setIndex(newIndex);
  };

  const handleOnNext = () => {
    const newIndex = index === rankingData.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
  };

  return (
    <div className="rankings-container" data-content={!!currentRankinData}>
      {isLoading && <Loader />}

      {!isLoading && rankingData && Object.keys(rankingData).length !== 0 && (
        <>
          <ChevronNavigation
            onPrevious={handleOnPrevious}
            onNext={handleOnNext}
            title={currentRankinData.Competition.Nom}
            hasArrow={rankingData.length > 1}
            extra={
              <a
                href={`${REACT_APP_ICBAD_URL}competition/${currentRankinData.Competition.Autorisation}/tableau/${currentRankinData.Tableau.ID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInfoCircle as any} />
              </a>
            }
          />

          <List
            data={currentRankinData.Classements}
            currentClubId={currentClubId}
          />
        </>
      )}
    </div>
  );
};

export default Rankings;
