import React from "react";

import Title from "components/Title";
import SubscribeForm from "./components/SubscribeForm";
import { SUBSCRIBENOW_DEFAULT_PROPS } from "./editor.fields";

interface IProps {
  title?: string;
  description?: string;
  bottomText?: string;
}

const SubscribeNow: React.FC<IProps> = (props: IProps) => {
  const {
    title = SUBSCRIBENOW_DEFAULT_PROPS.title,
    description = SUBSCRIBENOW_DEFAULT_PROPS.description,
    bottomText,
  } = props;

  return (
    <div className="subscribe-now-container">
      <Title style={{ textAlign: "center" }} firstWordColor="#fff" content={title} />

      <p>{description}</p>

      <SubscribeForm />

      {bottomText && <p className="text-center">{bottomText}</p>}
    </div>
  );
};

export default SubscribeNow;
