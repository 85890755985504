import React from "react";

import { IMeetingTeam } from "fullComponents/Meetings/meetings.interfaces";

interface IProps {
  team: IMeetingTeam;
}

const { REACT_APP_ICBAD_URL } = process.env;

const TeamLink: React.FC<IProps> = (props: IProps) => {
  const { team } = props;

  return (
    <span className="u-bold">
      <a
        href={`${REACT_APP_ICBAD_URL}equipe/${team.ID}`}
        target="_blank"
        rel="noopener noreferrer"
        title={team.Nom}
      >
        {team.Nom}
      </a>
    </span>
  );
};

export default TeamLink;
